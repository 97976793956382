import React, { useEffect, useState, useMemo, useCallback } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Grid,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Button as MuiButton,
  Divider,
  TextField as MuiTextField,
  Alert as MuiAlert,
  Typography,
} from "@mui/material";
import axios from "axios";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import SelectWrapper from "./SelectBox";
import { useDropzone } from "react-dropzone";
import moment from "moment";
import apiUtils from "../utils/apiUtils";

const Card = styled(MuiCard)(spacing);
const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Button = styled(MuiButton)(spacing);

const timeOut = (time) => new Promise((res) => setTimeout(res, time));

const createValidationSchema = (showExtraFields) => {
  let schema = Yup.object().shape({
    customerId: Yup.string().required("Required"),
    invoiceNumber: Yup.string().required("Required"),
    invoiceDate: Yup.string().required("Required"),
    supplierId: Yup.string().required("Required"),
    countryCode: Yup.string().required("Required"),
    claimProductTypeCode: Yup.string().required("Required"),
    currencyId: Yup.string().required("Required"),
    invoiceGrossAmount: Yup.string()
      .required("Required")
      .matches(/^-?[0-9]+(\.[0-9]+)?$/, "Invalid format. Only numbers, dots, and an optional negative sign are allowed."),
    invoiceVATAmountLocalCurrency: Yup.string()
      .required("Required")
      .matches(/^-?[0-9]+(\.[0-9]+)?$/, "Invalid format. Only numbers, dots, and an optional negative sign are allowed."),
    claimId: Yup.string().required("Required"),
    // invoiceLabelId: Yup.string().required("Required"),
  });

  if (showExtraFields) {
    schema = schema.concat(
      Yup.object().shape({
        languageCode: Yup.string().required("Language is required"),
        freeText: Yup.string().required("Free text is required"),
      })
    );
  }

  return schema;
};

export default function ViewCustomerInvoiceDialog(props) {
  const { viewCustomerInvoiceDialog, setViewCustomerInvoiceDialog, refreshGrid } = props;

  // console.log("ViewCustomerInvoiceDialog props:", props);

  // State variables
  const [selectedClaimDate, setSelectedClaimDate] = useState("");
  const [isNewFile, setIsNewFile] = useState(false);
  const [isRemovedFile, setIsRemovedFile] = useState(false);
  const [isUpdatedFile, setIsUpdatedFile] = useState(false);
  const [fileContent, setFileContent] = useState(null);
  // const [editedData, setEditedData] = useState({});
  const [customerId, setCustomerId] = useState("");
  const [currentCountryCode, setCurrentCountryCode] = useState(viewCustomerInvoiceDialog?.params?.countryCode || "");
  const [currentInvoiceState, setCurrentInvoiceState] = useState(viewCustomerInvoiceDialog?.params?.invoiceStateId || "");
  const [showExtraFields, setShowExtraFields] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  // Data fetched from API
  const productTypeData = apiUtils.useApiData("/api/v1/productTypes");

  // Additional state for product codes
  // const [selectedCustomProductLanguage, setSelectedCustomProductLanguage] = useState("");
  const initialCustomProductLanguage = viewCustomerInvoiceDialog?.params?.productCodeDescriptionLanguageCountry || "";
  const initialProductCodeDescription = viewCustomerInvoiceDialog?.params?.productCodeDescription || "";

  // Initial product type ID
  // const initialProductTypeId = viewCustomerInvoiceDialog?.params?.claimProductTypeId;

  // States for initial product codes
  const [initialProductCode, setInitialProductCode] = useState("");
  const [initialProductSubCode, setInitialProductSubCode] = useState("");

  // States for invoice amounts
  const [initialInvoiceGrossAmount, setInitialInvoiceGrossAmount] = useState(
    viewCustomerInvoiceDialog?.params?.invoiceGrossAmount || ""
  );
  const [initialInvoiceVATAmountLocalCurrency, setInitialInvoiceVATAmountLocalCurrency] = useState(
    viewCustomerInvoiceDialog?.params?.invoiceVATAmountLocalCurrency || ""
  );

  // States for selected product codes
  const [selectedProductCode, setSelectedProductCode] = useState("");
  const [selectedProductSubCode, setSelectedProductSubCode] = useState("");
  const [groupedProductCodes, setGroupedProductCodes] = useState([]);
  const [groupedProductSubCodes, setGroupedProductSubCodes] = useState([]);

  // Loading state for productTypeData
  const [isProductTypeDataLoaded, setIsProductTypeDataLoaded] = useState(false);

  // Handle confirmation dialog
  const handleConfirmationDialogClose = () => {
    setShowConfirmationDialog(false);
  };

  const handleRemoveFileClick = () => {
    setShowConfirmationDialog(true);
  };

  const filteredSuppliers = useMemo(() => {
    return props.supplierArr.filter((supplier) => supplier.countryCode === currentCountryCode);
  }, [props.supplierArr, currentCountryCode]);

  // File URL state
  const [fileUrl, setFileUrl] = useState(null);

  // Handle file drop
  const onDropAccepted = useCallback(
    (acceptedFiles) => {
      const selectedFile = acceptedFiles[0];
      const href = URL.createObjectURL(selectedFile);
      setFileUrl(href);
      if (isRemovedFile) {
        if (!isNewFile) {
          setIsUpdatedFile(true);
        }
      } else {
        setIsNewFile(true);
      }
      setFileContent(selectedFile);
      setIsRemovedFile(false);
      refreshGrid();
    },
    [isRemovedFile, isNewFile, refreshGrid]
  );

  // Handle file download
  const downloadFile = async (fileId) => {
    setFileUrl("");
    if (fileId >= 1) {
      try {
        const response = await axios({
          url: "/api/v1/azureblobstorage/" + fileId,
          method: "GET",
          responseType: "blob",
        });
        const href = URL.createObjectURL(response.data);
        setFileUrl(href);
      } catch (error) {
        setFileUrl("NoFile");
        console.error("Error downloading file:", error);
      }
    } else {
      setFileUrl("NoFile");
    }
  };

  // Effect to handle file download on dialog open
  useEffect(() => {
    if (viewCustomerInvoiceDialog?.downloadFile) {
      const fileId = viewCustomerInvoiceDialog?.params?.fileId;
      if (fileId === 0 || fileId === null) {
        setFileUrl("NoFile");
      } else {
        downloadFile(fileId);
      }
      viewCustomerInvoiceDialog.downloadFile = false;
    }
  }, [viewCustomerInvoiceDialog]);

  // Effect to set customer ID
  useEffect(() => {
    setCustomerId(viewCustomerInvoiceDialog?.params?.entityId || "");
  }, [viewCustomerInvoiceDialog?.params?.entityId]);

  // Effect to set country code
  useEffect(() => {
    setCurrentCountryCode(viewCustomerInvoiceDialog?.params?.countryCode || "");
  }, [viewCustomerInvoiceDialog?.params?.countryCode]);

  // Effect to set invoice state
  useEffect(() => {
    setCurrentInvoiceState(viewCustomerInvoiceDialog?.params?.invoiceStateId || "");
  }, [viewCustomerInvoiceDialog?.params?.invoiceStateId]);

  // Effect to set selected claim date
  useEffect(() => {
    if (viewCustomerInvoiceDialog?.params?.invoiceDate) {
      setSelectedClaimDate(moment(viewCustomerInvoiceDialog?.params?.invoiceDate).format("YYYY-MM-DD"));
    }
  }, [viewCustomerInvoiceDialog?.params?.invoiceDate]);

  // Effect to handle invoice state when dialog opens
  useEffect(() => {
    if (viewCustomerInvoiceDialog.isOpen) {
      setCurrentInvoiceState(viewCustomerInvoiceDialog.params.invoiceStateId || "");
    }
  }, [viewCustomerInvoiceDialog]);

  // Effect to initialize product codes once productTypeData is loaded
  useEffect(() => {
    if (productTypeData && productTypeData.length > 0 && viewCustomerInvoiceDialog?.params?.claimProductTypeId !== undefined) {
      if (viewCustomerInvoiceDialog.params.claimProductTypeId === -1) {
        setInitialProductCode("10");
        setInitialProductSubCode("99");
        setSelectedProductCode("10");
        setSelectedProductSubCode("99");
      } else {
        const product = productTypeData.find(
          (product) => product.productTypeId === viewCustomerInvoiceDialog.params.claimProductTypeId
        );
        if (product) {
          setInitialProductCode(product.categoryCode);
          setInitialProductSubCode(product.subCode === "" ? "98" : product.subCode);
          setSelectedProductCode(product.categoryCode);
          setSelectedProductSubCode(product.subCode === "" ? "98" : product.subCode);
        }
      }
      setIsProductTypeDataLoaded(true);
    }
  }, [viewCustomerInvoiceDialog?.params?.claimProductTypeId, productTypeData]);

  // Effect to set grouped product codes based on country code
  useEffect(() => {
    if (!productTypeData) return;

    const groupedProductTypes = productTypeData
      .filter((item) => item.countryCode === currentCountryCode)
      .reduce((acc, item) => {
        const key = item.categoryCode;
        acc[key] = acc[key] || [];
        acc[key].push(`${item.categoryCode}. ${item.productShortDescription}`);
        return acc;
      }, {});

    const groupedOptions = Object.entries(groupedProductTypes).map(([key, values]) => ({
      key: key,
      value: values[0],
    }));

    setGroupedProductCodes(groupedOptions);
  }, [productTypeData, currentCountryCode]);

  // Effect to set grouped product subcodes based on selected product code
  useEffect(() => {
    if (!selectedProductCode || !productTypeData) return;

    const groupedSubCodeOptions = productTypeData
      .filter((item) => item.countryCode === currentCountryCode && item.categoryCode === selectedProductCode)
      .reduce((acc, item) => {
        if (item.subCode.trim() === "") {
          return acc;
        }
        const key = item.subCode;
        acc[key] = acc[key] || [];
        acc[key].push(`${item.subCode}. ${item.productLongDescription}`);

        if (selectedProductCode === "10") {
          acc["99"] = acc["99"] || [];
          acc["99"].push("99. Custom Value");
        }

        return acc;
      }, {});

    const groupedSubCodeOptionsArray = Object.entries(groupedSubCodeOptions)
      .map(([key, values]) => ({
        key: key,
        value: values[0],
      }))
      .sort((a, b) => {
        const numA = parseFloat(a.key);
        const numB = parseFloat(b.key);
        return !isNaN(numA) && !isNaN(numB) ? numA - numB : a.key.localeCompare(b.key);
      });

    if (groupedSubCodeOptionsArray.length === 0) {
      groupedSubCodeOptionsArray.push({ key: "98", value: "98. N/A" });
      if (selectedProductCode === "10") {
        groupedSubCodeOptionsArray.push({ key: "99", value: "99. Custom Value" });
      }
    }

    setGroupedProductSubCodes(groupedSubCodeOptionsArray);

    if (groupedSubCodeOptionsArray.length === 1) {
      setSelectedProductSubCode(groupedSubCodeOptionsArray[0].key);
    }
  }, [productTypeData, currentCountryCode, selectedProductCode]);

  // Effect to show extra fields based on product description
  useEffect(() => {
    const productCodeDescription = viewCustomerInvoiceDialog?.params?.productCodeDescription;
    const productCodeDescriptionLanguageCountry = viewCustomerInvoiceDialog?.params?.productCodeDescriptionLanguageCountry;

    if (productCodeDescription && productCodeDescriptionLanguageCountry) {
      setShowExtraFields(true);
      setSelectedProductCode("10");
      setSelectedProductSubCode("99");
    } else {
      setShowExtraFields(false);
    }
  }, [viewCustomerInvoiceDialog]);

  // Handle product subcode change
  const handleProductSubCodeChange = (e) => {
    setSelectedProductSubCode(e.target.value);
    setShowExtraFields(e.target.value === "99");
  };

  // Handle custom product language change
  const handleCustomProductLanguageChange = (e) => {
    // setSelectedCustomProductLanguage(e.target.value);
  };

  // Custom selectable languages
  const customSelectableLanguages = [
    { key: "en", value: "English" },
    { key: "ro", value: "Romanian" },
    { key: "cz", value: "Czech" },
    { key: "sk", value: "Slovak" },
  ];

  // Handle file removal
  const handleRemoveFile = () => {
    setIsRemovedFile(true);
    setFileUrl("NoFile");
    refreshGrid();
    setShowConfirmationDialog(false);
  };

  // Handle claim date change
  const handleSelectedClaimDateValue = (event) => {
    setSelectedClaimDate(event.target.value);
  };

  // Handle country change
  const handleSelectedCountryChange = (event) => {
    setCurrentCountryCode(event.target.value);
  };

  // Handle invoice state change
  const handleSelectedInvoiceStateChange = (event) => {
    setCurrentInvoiceState(event.target.value);
  };

  // Validation schema
  const validationSchema = createValidationSchema(showExtraFields);

  // Handle form submission
  const handleSubmit = async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
    try {
      await validationSchema.validate(values, { abortEarly: false });
    } catch (validationErrors) {
      const errors = {};

      validationErrors.inner.forEach((error) => {
        if (!errors[error.path]) {
          errors[error.path] = error.message;
        }
      });

      setErrors(errors);
      return;
    }

    setSubmitting(true);
    await timeOut(1500);
    let fileId = 0;
    const invoiceId = viewCustomerInvoiceDialog?.params?.customerInvoiceId;

    try {
      if (isNewFile || isUpdatedFile) {
        const fileInfo = {
          documentType: 1,
          creationDate: moment().format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z",
          createdBy: 1,
          fileName: fileContent.name,
          fileSize: fileContent.size,
          mimeType: fileContent.type,
        };

        if (isRemovedFile) {
          fileId = 0;
        } else {
          const response = await axios.post("/api/v1/file", fileInfo);
          fileId = response.data.fileId;
          const formData = new FormData();
          formData.append("file", fileContent, fileId);
          await axios.post("/api/v1/azureblobstorage", formData);
          const fileIdInfo = {
            fileId: fileId,
          };
          if (isUpdatedFile || isNewFile) {
            await axios.put(`/api/v1/customerInvoices/updateFileId/${invoiceId}`, fileIdInfo);
          }
        }
      } else {
        if (isRemovedFile) {
          fileId = 0;
        } else {
          fileId = viewCustomerInvoiceDialog?.params?.fileId || 0;
        }
      }

      setIsNewFile(false);
      setIsRemovedFile(false);
      setIsUpdatedFile(false);

      let claimProductTypeId;
      if (values.claimProductTypeCode === "10" && (values.claimProductSubCodeId === "99" || values.claimProductSubCodeId === 99)) {
        claimProductTypeId = "-1";
      } else {
        values.freeText = "";
        values.languageCode = "";
        console.log("values:", values);
        const matchedProduct = productTypeData.find(
          (x) =>
            x.countryCode === values.countryCode &&
            x.categoryCode === values.claimProductTypeCode &&
            x.subCode ===
              (values.claimProductSubCodeId === "98" || values.claimProductSubCodeId === 98 ? "" : values.claimProductSubCodeId)
        );
        claimProductTypeId = matchedProduct ? matchedProduct.productTypeId : "";
      }

      const updatedInvoice = {
        entityId: customerId,
        invoiceNumber: values.invoiceNumber,
        invoiceDate: selectedClaimDate,
        supplierId: values.supplierId,
        countryCode: values.countryCode,
        claimProductTypeId: claimProductTypeId,
        currencyId: values.currencyId,
        invoiceGrossAmount: values.invoiceGrossAmount,
        invoiceVATAmountLocalCurrency: values.invoiceVATAmountLocalCurrency,
        claimId: values.claimId,
        invoiceLabelId: values.invoiceLabelId,
        fileId: fileId,
        invoiceStateId: currentInvoiceState,
        productCodeDescription: values.freeText,
        productCodeDescriptionLanguageCountry: values.languageCode,
      };
      console.log("Updated Invoice:", updatedInvoice);
      // setEditedData(updatedInvoice);
      await axios.put(
        `/api/v1/customerInvoices/updateInvoiceEditForm/${viewCustomerInvoiceDialog?.params?.customerInvoiceId}`,
        updatedInvoice
      );

      viewCustomerInvoiceDialog.handleFile(updatedInvoice);
    } catch (error) {
      console.error("Update error:", error);
      alert("An error occurred while updating the invoice.");
    } finally {
      refreshGrid();
      setSubmitting(false);
    }

    // Handle invoice amounts change
    let invoiceAmountsChanged = false;

    if (initialInvoiceGrossAmount !== values.invoiceGrossAmount) {
      if (viewCustomerInvoiceDialog?.params?.claimId) {
        invoiceAmountsChanged = true;
      }
      setInitialInvoiceGrossAmount(values.invoiceGrossAmount);
    }
    if (initialInvoiceVATAmountLocalCurrency !== values.invoiceVATAmountLocalCurrency) {
      if (viewCustomerInvoiceDialog?.params?.claimId) {
        invoiceAmountsChanged = true;
      }
      setInitialInvoiceVATAmountLocalCurrency(values.invoiceVATAmountLocalCurrency);
    }

    if (invoiceAmountsChanged) {
      try {
        await axios.post(`/api/v1/claims/updateInvoiceAmounts/${viewCustomerInvoiceDialog?.params?.claimId}`);
      } catch (error) {
        console.error("Error updating invoice amounts:", error);
      } finally {
        refreshGrid();
      }
    }
  };

  // Styles for Dropzone
  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
    minHeight: "300px",
    minWidth: "600px",
    marginTop: "200px",
    marginLeft: "200px",
  };

  const focusedStyle = {
    borderColor: "#2196f3",
  };

  const acceptStyle = {
    borderColor: "#00e676",
  };

  const rejectStyle = {
    borderColor: "#ff1744",
  };

  // Styled Dropzone Component
  function StyledDropzone() {
    const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
      accept: { "application/pdf": [] },
      onDropAccepted,
    });

    const style = useMemo(
      () => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {}),
      }),
      [isFocused, isDragAccept, isDragReject]
    );

    return (
      <div className="container">
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <p
            style={{
              display: "flex",
              margin: "auto",
              justifyContent: "center",
            }}
          >
            No files are currently attached to this invoice. <br />
            Drag 'n' drop the invoice file here, or click to select
          </p>
        </div>
      </div>
    );
  }

  // Define initialValues after ensuring data is loaded
  const initialValues = isProductTypeDataLoaded
    ? {
        customerId: customerId,
        invoiceNumber: viewCustomerInvoiceDialog?.params?.invoiceNumber || "",
        invoiceDate: viewCustomerInvoiceDialog?.params?.invoiceDate || "",
        supplierId: viewCustomerInvoiceDialog?.params?.supplierId || "",
        countryCode: viewCustomerInvoiceDialog?.params?.countryCode || "",
        productTypeId: viewCustomerInvoiceDialog?.params?.productTypeId || "",
        claimProductTypeCode: selectedProductCode || initialProductCode,
        currencyId: viewCustomerInvoiceDialog?.params?.currencyId || "",
        invoiceGrossAmount: parseFloat(viewCustomerInvoiceDialog?.params?.invoiceGrossAmount || "0").toFixed(2),
        invoiceVATAmountLocalCurrency: parseFloat(viewCustomerInvoiceDialog?.params?.invoiceVATAmountLocalCurrency || "0").toFixed(2),
        invoiceVATAmountEUR: viewCustomerInvoiceDialog?.params?.invoiceVATAmountEUR || "",
        claimId: viewCustomerInvoiceDialog?.params?.claimId || 0,
        invoiceStateId: viewCustomerInvoiceDialog?.params?.invoiceStateId || "",
        invoiceLabelId: viewCustomerInvoiceDialog?.params?.invoiceLabelId || 0,
        languageCode: initialCustomProductLanguage || "",
        freeText: viewCustomerInvoiceDialog?.params?.freeText || initialProductCodeDescription || "",
        claimProductSubCodeId: selectedProductSubCode || initialProductSubCode || "",
      }
    : {};

  // // Console logs for debugging
  // useEffect(() => {
  //   console.log("viewCustomerInvoiceDialog.params:", viewCustomerInvoiceDialog.params);
  //   console.log("customerId:", customerId);
  //   console.log("selectedProductCode:", selectedProductCode);
  //   console.log("initialProductCode:", initialProductCode);
  //   console.log("initialProductCodeDescription:", initialProductCodeDescription);
  //   console.log("selectedProductSubCode:", selectedProductSubCode);
  //   console.log("initialProductSubCode:", initialProductSubCode);
  //   console.log("GroupedProductCodes:", groupedProductCodes);
  //   console.log("GroupedProductSubCodes:", groupedProductSubCodes);
  //   console.log("initialCustomProductLanguage:", initialCustomProductLanguage);
  //   console.log("props.supplierArr:", props.supplierArr);
  //   console.log("props.countryArr:", props.countryArr);
  //   console.log("currentCountryCode", currentCountryCode);
  // }, [
  //   viewCustomerInvoiceDialog.params,
  //   customerId,
  //   selectedProductCode,
  //   initialProductCode,
  //   initialProductCodeDescription,
  //   selectedProductSubCode,
  //   initialProductSubCode,
  //   initialCustomProductLanguage,
  //   props.supplierArr,
  // ]);

  // // Console logs for debugging
  // useEffect(() => {
  //   console.log("Formik initialValues:", initialValues);
  //   console.log("Formik errors:", viewCustomerInvoiceDialog?.errors);
  //   console.log("Formik touched:", viewCustomerInvoiceDialog?.touched);
  // }, [initialValues, viewCustomerInvoiceDialog?.errors, viewCustomerInvoiceDialog?.touched]);

  return (
    <Dialog
      open={viewCustomerInvoiceDialog.isOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xl"
    >
      <DialogTitle id="alert-dialog-title">{viewCustomerInvoiceDialog.title}</DialogTitle>
      <Divider />
      <DialogContent>
        {isProductTypeDataLoaded ? (
          <Formik
            // key={viewCustomerInvoiceDialog?.params?.customerInvoiceId}
            initialValues={initialValues}
            enableReinitialize={false}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, resetForm, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => {
              console.log("Formik Errors:", errors);
              console.log("Formik Touched:", touched);

              return (
                <Card mb={6}>
                  <CardContent>
                    {/* Display error or success messages */}
                    {Object.keys(errors).length > 0 && (
                      <Alert severity="error" my={3}>
                        Please fix the highlighted errors before submitting.
                      </Alert>
                    )}
                    {isSubmitting && (
                      <Box minHeight="700px" display="flex" justifyContent="center" my={20} mx={40}>
                        <CircularProgress />
                      </Box>
                    )}
                    {!isSubmitting && (
                      <form onSubmit={handleSubmit}>
                        <Grid container spacing={6} sx={{ height: "100%" }}>
                          {/* Left Pane */}
                          <Grid item>
                            <Grid container spacing={2} direction="column">
                              <Grid item xs={12} style={{ padding: 0 }}>
                                <TextField
                                  name="invoiceNumber"
                                  label="Invoice Number"
                                  value={values.invoiceNumber}
                                  error={Boolean(touched.invoiceNumber && errors.invoiceNumber)}
                                  fullWidth
                                  helperText={touched.invoiceNumber && errors.invoiceNumber}
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                  variant="outlined"
                                  my={2}
                                  style={{ width: 200 }}
                                  type="text"
                                />
                              </Grid>
                              <Grid item xs={12} style={{ padding: 0 }}>
                                <TextField
                                  id="invoiceDate"
                                  name="invoiceDate"
                                  label="Invoice Date"
                                  type="date"
                                  value={selectedClaimDate}
                                  onChange={handleSelectedClaimDateValue}
                                  fullWidth
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  style={{ width: 200, paddingTop: 8, paddingBottom: 8 }}
                                />
                              </Grid>
                              <Grid item xs={12} style={{ padding: 0 }}>
                                <SelectWrapper
                                  name="invoiceStateId"
                                  label="Invoice State"
                                  value={currentInvoiceState || viewCustomerInvoiceDialog.params.invoiceStateId}
                                  onChange={handleSelectedInvoiceStateChange}
                                  options={props.invoiceStateArr.map((item) => ({
                                    key: item.invoiceStateId,
                                    value: item.invoiceStateDescription,
                                  }))}
                                  my={2}
                                  style={{ width: 200 }}
                                />
                              </Grid>
                              <Grid item xs={12} style={{ padding: 0 }}>
                                <SelectWrapper
                                  name="invoiceLabelId"
                                  label="Invoice Label"
                                  value={values.invoiceLabelId}
                                  onChange={handleChange}
                                  options={[
                                    { key: 0, value: "N/A" },
                                    ...props.invoiceLabelArr.map((item) => ({
                                      key: item.invoiceLabelId,
                                      value: item.invoiceLabelDescription,
                                    })),
                                  ]}
                                  my={2}
                                  style={{ width: 200 }}
                                />
                              </Grid>

                              <Grid item xs={12} style={{ padding: 0 }}>
                                <SelectWrapper
                                  name="supplierId"
                                  label="Supplier"
                                  options={JSON.parse(
                                    JSON.stringify(JSON.parse(JSON.stringify(filteredSuppliers).split('"supplierId":').join('"key":')))
                                      .split('"supplierName":')
                                      .join('"value":')
                                  )}
                                  style={{ width: 200 }}
                                />
                              </Grid>
                              <Grid item xs={12} style={{ padding: 0 }}>
                                <SelectWrapper
                                  name="countryCode"
                                  label="Country"
                                  onChange={handleSelectedCountryChange}
                                  options={props.countryArr.map((item) => ({
                                    key: item.countryId,
                                    value: item.countryCode,
                                  }))}
                                  style={{ width: 200 }}
                                />
                              </Grid>
                              <Grid item xs={12} style={{ padding: 0 }}>
                                <SelectWrapper
                                  name="claimProductTypeCode"
                                  label="Product Type"
                                  options={groupedProductCodes}
                                  value={values.claimProductTypeCode}
                                  onChange={(e) => {
                                    handleChange(e);
                                    setSelectedProductCode(e.target.value);
                                  }}
                                  error={Boolean(touched.claimProductTypeCode && errors.claimProductTypeCode)}
                                  fullWidth
                                  helperText={touched.claimProductTypeCode && errors.claimProductTypeCode}
                                  style={{ width: 200 }}
                                />
                              </Grid>
                              <Grid item xs={12} style={{ padding: 0 }}>
                                <SelectWrapper
                                  name="claimProductSubCodeId"
                                  label="Product SubCode"
                                  options={groupedProductSubCodes}
                                  value={values.claimProductSubCodeId}
                                  onChange={(e) => {
                                    handleChange(e);
                                    handleProductSubCodeChange(e);
                                  }}
                                  error={Boolean(touched.claimProductSubCodeId && errors.claimProductSubCodeId)}
                                  fullWidth
                                  helperText={touched.claimProductSubCodeId && errors.claimProductSubCodeId}
                                  style={{ width: 200 }}
                                />
                              </Grid>
                              {showExtraFields && (
                                <>
                                  <Grid item xs={12} style={{ padding: 0 }}>
                                    <SelectWrapper
                                      name="languageCode"
                                      label="FreeText Language"
                                      options={customSelectableLanguages}
                                      value={values.languageCode}
                                      onChange={handleCustomProductLanguageChange}
                                      error={Boolean(touched.languageCode && errors.languageCode)}
                                      fullWidth
                                      helperText={touched.languageCode && errors.languageCode}
                                      style={{ width: 200 }}
                                    />
                                  </Grid>
                                  <Grid item xs={12} style={{ padding: 0 }}>
                                    <TextField
                                      name="freeText"
                                      label="FreeText"
                                      value={values.freeText}
                                      error={Boolean(touched.freeText && errors.freeText)}
                                      fullWidth
                                      helperText={touched.freeText && errors.freeText}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      variant="outlined"
                                      my={2}
                                      style={{ width: 200 }}
                                    />
                                  </Grid>
                                </>
                              )}
                              <Grid item xs={12} style={{ padding: 0 }}>
                                <SelectWrapper
                                  name="currencyId"
                                  label="Currency"
                                  options={props.currencyArr.map((item) => ({
                                    key: item.currencyId,
                                    value: item.isoCode,
                                  }))}
                                  style={{ width: 200 }}
                                />
                              </Grid>
                              <Grid item xs={12} style={{ padding: 0 }}>
                                <TextField
                                  name="invoiceGrossAmount"
                                  label="Invoice Net Amount"
                                  value={values.invoiceGrossAmount}
                                  error={Boolean(touched.invoiceGrossAmount && errors.invoiceGrossAmount)}
                                  fullWidth
                                  helperText={touched.invoiceGrossAmount && errors.invoiceGrossAmount}
                                  onBlur={(e) => {
                                    handleBlur(e);
                                    const val = parseFloat(e.target.value);
                                    setFieldValue("invoiceGrossAmount", Number.isNaN(val) ? "0.00" : val.toFixed(2));
                                  }}
                                  onChange={handleChange}
                                  variant="outlined"
                                  my={2}
                                  style={{ width: 200 }}
                                />
                              </Grid>
                              <Grid item xs={12} style={{ padding: 0 }}>
                                <TextField
                                  name="invoiceVATAmountLocalCurrency"
                                  label="Invoice VAT Amount"
                                  value={values.invoiceVATAmountLocalCurrency}
                                  error={Boolean(touched.invoiceVATAmountLocalCurrency && errors.invoiceVATAmountLocalCurrency)}
                                  fullWidth
                                  helperText={
                                    touched.invoiceVATAmountLocalCurrency && errors.invoiceVATAmountLocalCurrency
                                      ? errors.invoiceVATAmountLocalCurrency
                                      : ""
                                  }
                                  onBlur={(e) => {
                                    handleBlur(e);
                                    const val = parseFloat(e.target.value);
                                    setFieldValue("invoiceVATAmountLocalCurrency", Number.isNaN(val) ? "0.00" : val.toFixed(2));
                                  }}
                                  onChange={handleChange}
                                  variant="outlined"
                                  my={2}
                                  style={{ width: 200 }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>

                          {/* Right Pane */}
                          <Grid item sx={{ flexGrow: 1 }}>
                            <Box style={{ width: "100%", height: "100%", overflow: "auto", display: "flex", flexDirection: "column" }}>
                              {fileUrl === "" ? (
                                <div className="App">
                                  <Typography
                                    sx={{
                                      fontSize: "21px",
                                      mt: 2,
                                      pt: "20px",
                                      pb: "20px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      border: 1,
                                      borderColor: "lightgray",
                                      width: "100%",
                                      height: "100%",
                                      boxSizing: "border-box",
                                    }}
                                  >
                                    <CircularProgress size={30} sx={{ mr: 2 }} />
                                    Loading file, please wait!
                                  </Typography>
                                </div>
                              ) : fileUrl === "NoFile" || fileUrl === null ? (
                                <div className="App">
                                  <StyledDropzone />
                                </div>
                              ) : (
                                <Box style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                  <Box display="flex" justifyContent="space-between" alignItems="center" mt={3} mb={1}>
                                    <Button
                                      variant="outlined"
                                      color="primary"
                                      disabled={!fileUrl || fileUrl === "NoFile"}
                                      onClick={async () => {
                                        if (!fileUrl || fileUrl === "NoFile") return;
                                        const response = await fetch(fileUrl);
                                        const blob = await response.blob();
                                        const url = window.URL.createObjectURL(blob);
                                        const a = document.createElement("a");
                                        a.href = url;
                                        a.download = `${
                                          viewCustomerInvoiceDialog?.params?.customerNumber +
                                          "-" +
                                          viewCustomerInvoiceDialog?.params?.invoiceNumber
                                        }.pdf`;
                                        document.body.appendChild(a);
                                        a.click();
                                        document.body.removeChild(a);
                                        window.URL.revokeObjectURL(url);
                                      }}
                                    >
                                      Download PDF
                                    </Button>

                                    <Button type="button" variant="contained" color="error" onClick={handleRemoveFileClick}>
                                      Remove File
                                    </Button>
                                  </Box>

                                  <Dialog open={showConfirmationDialog} onClose={handleConfirmationDialogClose}>
                                    <DialogTitle>Confirmation</DialogTitle>
                                    <DialogContent>
                                      <DialogContentText>Are you sure you want to delete the invoice copy?</DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                      <Button onClick={handleConfirmationDialogClose} color="primary">
                                        Cancel
                                      </Button>
                                      <Button onClick={handleRemoveFile} color="primary">
                                        Delete
                                      </Button>
                                    </DialogActions>
                                  </Dialog>
                                  <object
                                    width="100%"
                                    height="100%"
                                    data={fileUrl}
                                    type="application/pdf"
                                    style={{
                                      marginTop: "8px",
                                      flex: 1,
                                      width: "100%",
                                      height: "100%",
                                      boxSizing: "border-box",
                                    }}
                                  >
                                    <iframe
                                      title="file"
                                      src={fileUrl + "&embedded=true"}
                                      style={{ width: "100%", height: "100vh" }}
                                    ></iframe>
                                  </object>
                                </Box>
                              )}
                            </Box>
                          </Grid>
                        </Grid>

                        {/* Submit and Close Buttons */}
                        <Box display="flex" justifyContent="flex-end" mt={3}>
                          <Button type="submit" variant="contained" color="primary" my={2} mr={4}>
                            Update Invoice
                          </Button>
                          <Button
                            type="button"
                            variant="contained"
                            color="secondary"
                            my={2}
                            onClick={() => {
                              resetForm(initialValues);
                              setViewCustomerInvoiceDialog({
                                ...viewCustomerInvoiceDialog,
                                isOpen: false,
                              });
                            }}
                          >
                            Close
                          </Button>
                        </Box>
                      </form>
                    )}
                  </CardContent>
                </Card>
              );
            }}
          </Formik>
        ) : (
          <Box display="flex" justifyContent="center" alignItems="center" height="400px">
            <CircularProgress />
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}
