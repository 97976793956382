import React, { useEffect, useState, useCallback, useMemo } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Grid,
  Box,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Button as MuiButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField as MuiTextField,
  Alert as MuiAlert,
  Typography,
  Collapse,
} from "@mui/material";
import axios from "axios";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import SelectWrapper from "./SelectBox";
import moment from "moment";
import apiUtils from "../utils/apiUtils";
// import { set } from "date-fns";

const Card = styled(MuiCard)(spacing);
const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Button = styled(MuiButton)(spacing);

// Validation schema factory
const createValidationSchema = (showExtraFields) => {
  let schema = Yup.object().shape({
    customerNumber: Yup.string().required("Required"),
    invoiceNumber: Yup.string().required("Required"),
    invoiceDate: Yup.string().required("Required"),
    supplierId: Yup.string().required("Required"),
    countryCode: Yup.string().required("Required"),
    claimProductTypeCode: Yup.string().required("Required"),
    claimProductSubCodeId: Yup.string().required("Required"),
    currencyId: Yup.string().required("Required"),
    invoiceGrossAmount: Yup.string()
      .required("Required")
      .matches(/^-?[0-9]+(\.[0-9]+)?$/, "Invalid format. Only numbers, dots, and an optional negative sign are allowed."),
    invoiceVATAmountLocalCurrency: Yup.string()
      .required("Required")
      .matches(/^-?[0-9]+(\.[0-9]+)?$/, "Invalid format. Only numbers, dots, and an optional negative sign are allowed."),
  });

  if (showExtraFields) {
    schema = schema.concat(
      Yup.object().shape({
        languageCode: Yup.string().required("Language is required"),
        freeText: Yup.string().required("Free text is required"),
      })
    );
  }

  return schema;
};

export default function ViewCheckAutomatedInvoiceDialog(props) {
  const { viewCustomerInvoiceDialog, setViewCustomerInvoiceDialog, activeTab, updateTimestamp } = props;

  // console.log("ViewCheckAutomatedInvoiceDialog -> viewCustomerInvoiceDialog", viewCustomerInvoiceDialog);

  const [openAlert, setOpenAlert] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  // console.log("ViewCheckAutomatedInvoiceDialog -> viewCustomerInvoiceDialog", props);

  const [showExtraFields, setShowExtraFields] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const productTypeData = apiUtils.useApiData("/api/v1/productTypes");

  const initialCustomProductLanguage = viewCustomerInvoiceDialog?.params?.productCodeDescriptionLanguageCountry;
  const initialProductCodeDescription = viewCustomerInvoiceDialog?.params?.productCodeDescription;

  const [currentProductCode, setCurrentProductCode] = useState(null);
  const [currentProductSubCode, setCurrentProductSubCode] = useState(null);
  const initialProductTypeId = parseInt(viewCustomerInvoiceDialog?.params?.productId, 10);

  const [currentCountryCode, setCurrentCountryCode] = useState(
    props.countryArr.find((country) => country.countryCode === viewCustomerInvoiceDialog?.params?.countryCodeSupplier)?.countryId || ""
  );

  const [groupedProductCodes, setGroupedProductCodes] = useState([]);
  const [groupedProductSubCodes, setGroupedProductSubCodes] = useState([]);

  const extractVATNumber = (taxId, countryArr) => {
    if (!taxId) return "";
    const sortedCountries = [...countryArr].sort((a, b) => b.countryCode.length - a.countryCode.length);
    const matchedCountry = sortedCountries.find((country) => taxId.toUpperCase().startsWith(country.countryCode.toUpperCase()));
    return matchedCountry ? taxId.slice(matchedCountry.countryCode.length) : taxId;
  };

  const [currentSupplierId, setCurrentSupplierId] = useState(null);
  // const [initialCountryId, setInitialCountryId] = useState(null);

  useEffect(() => {
    if (updateTimestamp) {
      // Set alert state
      setAlertSeverity("success"); // You can change this based on the update result
      setAlertMessage("invoice has been successfully updated!");
      setTimeout(() => setOpenAlert(false), 5000);
      setOpenAlert(true);
    }
  }, [updateTimestamp]);

  useEffect(() => {
    if (viewCustomerInvoiceDialog?.params?.taxIdSupplier) {
      const supplier = props.supplierArr.find(
        (supplier) =>
          supplier.supplierVATnr === extractVATNumber(viewCustomerInvoiceDialog?.params?.taxIdSupplier || "", props.countryArr)
      );
      if (supplier) {
        // alert("supplier.supplierId: " + supplier.supplierId);
        setCurrentSupplierId(supplier.supplierId);
      }
    }
  }, [viewCustomerInvoiceDialog?.params?.taxIdSupplier, props.supplierArr, props.countryArr]);

  useEffect(() => {
    if (viewCustomerInvoiceDialog?.params?.countryCodeSupplier) {
      const country = props.countryArr.find((country) => country.countryCode === viewCustomerInvoiceDialog.params.countryCodeSupplier);
      if (country) {
        setCurrentCountryCode(country.countryId);
      } else {
        setCurrentCountryCode("");
      }
    } else {
      setCurrentCountryCode("");
    }
  }, [viewCustomerInvoiceDialog?.params?.countryCodeSupplier, props.countryArr]);

  useEffect(() => {
    // Load initial product Code and SubCode based on the productTypeId
    if (productTypeData && productTypeData.length > 0 && initialProductTypeId) {
      const product = productTypeData.find((p) => p.productTypeId === initialProductTypeId);
      console.log("product", product);
      console.log(productTypeData);
      if (product) {
        setCurrentProductCode(product.categoryCode || "");
        setCurrentProductSubCode(product.subCode === "" ? "98" : product.subCode);
      } else {
        setCurrentProductCode("");
        setCurrentProductSubCode("");
      }
    } else {
      setCurrentProductCode("");
      setCurrentProductSubCode("");
    }
  }, [productTypeData, initialProductTypeId]);

  useEffect(() => {
    // alert("currentProductCode: " + currentProductCode);
    if (productTypeData && productTypeData.length > 0) {
      console.log("productTypeData", props.countryArr);
      const productCodes = productTypeData
        .filter((product) => product.countryCode === currentCountryCode)
        .map((product) => ({
          key: product.categoryCode,
          value: product.productShortDescription,
        }))
        .reduce((accumulator, current) => {
          // Check if the key already exists in the accumulator
          if (!accumulator.some((item) => item.key === current.key)) {
            accumulator.push(current);
          }
          return accumulator;
        }, []);

      console.log(productCodes);
      setGroupedProductCodes(productCodes);
      let productSubCodes = productTypeData
        .filter((product) => product.countryCode === currentCountryCode && product.categoryCode === currentProductCode)
        .map((product) => ({
          key: product.subCode,
          value: product.productLongDescription,
        }));

      if (productSubCodes.length === 0 || (productSubCodes[0].key === "" && productSubCodes.length === 1)) {
        productSubCodes = [{ key: "98", value: "98. N/A" }];
      }
      console.log(productSubCodes);
      setGroupedProductSubCodes(productSubCodes);
      // setCurrentProductSubCode(productSubCodes[0].key);
      console.log(productSubCodes);
    }
  }, [currentProductCode, currentCountryCode, currentSupplierId, productTypeData, props.countryArr]);

  const [fileUrl, setFileUrl] = useState(null);
  const downloadFile = useCallback(
    async (fileId, modelUsed) => {
      setFileUrl(""); // Reset file URL initially
      if (fileId !== "") {
        try {
          let hardError = 0;
          if (activeTab === 3) {
            hardError = 1;
          }
          console.log("hardError", hardError);
          const response = await axios({
            url: `/api/v1/azureocrblobstorage/${fileId}/${modelUsed}/${hardError}`,
            method: "GET",
            responseType: "blob",
          });
          const href = URL.createObjectURL(response.data);
          console.log("href", href);
          setFileUrl(href);
        } catch (error) {
          setFileUrl("NoFile");
          console.error("Error downloading file:", error);
        }
      } else {
        setFileUrl("NoFile");
      }
    },
    [activeTab]
  );

  useEffect(() => {
    if (viewCustomerInvoiceDialog?.downloadFile) {
      const fileId = viewCustomerInvoiceDialog?.params?.newFileName;
      const modelUsed = viewCustomerInvoiceDialog?.params?.modelUsed;

      if (fileId === "" || fileId === null) {
        setFileUrl("NoFile");
      } else {
        downloadFile(fileId, modelUsed);
      }

      setViewCustomerInvoiceDialog((prevState) => ({
        ...prevState,
        downloadFile: false,
      }));
    }
  }, [viewCustomerInvoiceDialog, setViewCustomerInvoiceDialog, downloadFile]);

  // const [currentCountryCode, setCurrentCountryCode] = useState(
  //   props.countryArr.find((country) => country.countryCode === viewCustomerInvoiceDialog?.params?.countryCodeSupplier)?.countryId || ""
  // );

  // Show extra fields based on Product Code Description
  useEffect(() => {
    const productCodeDescription = viewCustomerInvoiceDialog?.params?.productCodeDescription;
    const productCodeDescriptionLanguageCountry = viewCustomerInvoiceDialog?.params?.productCodeDescriptionLanguageCountry;

    if (productCodeDescription && productCodeDescriptionLanguageCountry) {
      setShowExtraFields(true);
    } else {
      setShowExtraFields(false);
    }
  }, [viewCustomerInvoiceDialog]);

  const customSelectableLanguages = [
    { key: "en", value: "English" },
    { key: "ro", value: "Romanian" },
    { key: "cz", value: "Czech" },
    { key: "sk", value: "Slovak" },
  ];

  const handleConfirmationDialogClose = () => {
    setShowConfirmationDialog(false);
  };

  // const handleRemoveFile = () => {
  //   setIsRemovedFile(true);
  //   setFileUrl("NoFile");
  //   refreshGrid();
  //   setShowConfirmationDialog(false);
  // };

  const handleSelectedProductCatagoryChange = (event, setFieldValue) => {
    const newProductCode = event.target.value;
    setCurrentProductCode(newProductCode);
    // Reset the sub code when product type changes
    setFieldValue("claimProductSubCodeId", "");
  };

  const handleSupplierChange = (event, setFieldValue) => {
    const selectedSupplierId = event.target.value;
    setCurrentSupplierId(selectedSupplierId);
    setFieldValue("supplierId", selectedSupplierId);

    const selectedSupplier = props.supplierArr.find((supplier) => supplier.supplierId === selectedSupplierId);
    if (selectedSupplier) {
      if (selectedSupplier.countryCode) {
        setCurrentCountryCode(selectedSupplier.countryCode);
        setFieldValue("countryCode", selectedSupplier.countryCode);
      }
      setFieldValue("taxIdSupplier", selectedSupplier.supplierVATnr);
      setFieldValue("claimProductTypeCode", "");
      setFieldValue("claimProductSubCodeId", "");
    }
  };

  // Initial form values
  const initialValues = {
    customerId: viewCustomerInvoiceDialog?.params?.entityId,
    invoiceNumber: viewCustomerInvoiceDialog?.params?.invoiceNumber || "",
    customerNumber: viewCustomerInvoiceDialog?.params?.customerNumber || "",
    invoiceDate: viewCustomerInvoiceDialog?.params?.invoiceDate
      ? moment(viewCustomerInvoiceDialog.params.invoiceDate).format("YYYY-MM-DD")
      : "",
    supplierId: currentSupplierId || "",
    countryCode: currentCountryCode || "",

    invoiceStateIdStatic: viewCustomerInvoiceDialog?.params?.invoiceStateId ?? 3,
    productTypeId: viewCustomerInvoiceDialog?.params?.productTypeId || "",
    claimProductTypeCode: currentProductCode || "",
    currencyId: viewCustomerInvoiceDialog?.params?.currencyId || "",
    invoiceGrossAmount: viewCustomerInvoiceDialog?.params?.totalExcludingTax || "",
    invoiceVATAmountLocalCurrency: viewCustomerInvoiceDialog?.params?.totalTax || "",
    invoiceVATAmountEUR: viewCustomerInvoiceDialog?.params?.invoiceVATAmountEUR || "",
    claimId: viewCustomerInvoiceDialog?.params?.claimId || "",
    invoiceLabelId: viewCustomerInvoiceDialog?.params?.invoiceLabelId || "",
    languageCode: initialCustomProductLanguage || "",
    freeText: viewCustomerInvoiceDialog?.params?.freeText
      ? viewCustomerInvoiceDialog?.params?.freeText
      : initialProductCodeDescription || "",
    claimProductSubCodeId: currentProductSubCode || "",
  };

  const validationSchema = createValidationSchema(showExtraFields);

  // Form submission handler
  const handleSubmit = async (values, { setErrors }) => {
    const matchedProduct = productTypeData.find(
      (item) =>
        item.categoryCode === values.claimProductTypeCode &&
        item.countryCode === values.countryCode &&
        item.subCode === (values.claimProductSubCodeId === "98" ? "" : values.claimProductSubCodeId)
    );

    let newCustomer = props.entitiesArr.find((entity) => entity.entityNumber === values.customerNumber);
    if (!newCustomer) {
      return setErrors({ customerNumber: "Customer not found" });
    }

    const updatedInvoice = {
      invoiceId: viewCustomerInvoiceDialog?.params?.invoiceId,
      invoiceNumber: values.invoiceNumber,
      customerNumber: values.customerNumber,
      invoiceDate: values.invoiceDate,
      supplierId: values.supplierId,
      countryCode: values.countryCode,
      countryCodeCustomer: props.countryArr.find((country) => country.countryId === newCustomer.countryId).countryCode,
      countryCodeSupplier: props.countryArr.find((country) => country.countryId === values.countryCode).countryCode,
      taxIdCustomer: props.entitiesArr.find((entity) => entity.entityNumber === values.customerNumber).vat,
      taxIdSupplier: props.supplierArr.find((supplier) => supplier.supplierId === values.supplierId).supplierVATnr,
      currencyId: values.currencyId,
      totalExcludingTax: values.invoiceGrossAmount,
      totalTax: values.invoiceVATAmountLocalCurrency,
      claimProductSubCodeId: values.claimProductSubCodeId,
      claimProductTypeCode: values.claimProductTypeCode,
      productId: matchedProduct.productTypeId,
      invoiceStateId: values.invoiceStateIdStatic,
    };

    props.updateInvoice(updatedInvoice);

    setViewCustomerInvoiceDialog({
      ...viewCustomerInvoiceDialog,
      isOpen: false,
    });
  };

  const filteredSuppliers = useMemo(() => {
    return props.supplierArr.filter((supplier) => supplier.countryCode === currentCountryCode);
  }, [props.supplierArr, currentCountryCode]);

  return (
    <Formik initialValues={initialValues} enableReinitialize={true} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
        <Card mb={6} sx={{ height: "100%", display: "flex", flexDirection: "column", overflow: "auto" }}>
          <CardContent sx={{ flexGrow: 1, display: "flex", flexDirection: "column", padding: 0, paddingTop: 2 }}>
            <Box sx={{ width: "100%", mt: 2 }}>
              <Collapse in={openAlert}>
                <Alert
                  severity={alertSeverity}
                  action={
                    <Button color="inherit" size="small" onClick={handleCloseAlert}>
                      Close
                    </Button>
                  }
                  sx={{ mb: 2 }}
                >
                  {alertMessage}
                </Alert>
              </Collapse>
            </Box>

            {activeTab !== 3 ? (
              <form onSubmit={handleSubmit} style={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
                <Grid container spacing={6} sx={{ flexGrow: 1 }}>
                  {/* Linker velden */}
                  <Grid item>
                    <Grid container spacing={2} direction="column">
                      <Grid item xs={12}>
                        <TextField
                          name="customerNumber"
                          label="Customer number"
                          value={values.customerNumber}
                          error={Boolean(touched.customerNumber && errors.customerNumber)}
                          helperText={touched.customerNumber && errors.customerNumber}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            handleChange(e);
                            const newCustomerNumber = e.target.value;
                            setViewCustomerInvoiceDialog((prevState) => ({
                              ...prevState,
                              params: {
                                ...prevState.params,
                                customerNumber: newCustomerNumber,
                              },
                            }));
                          }}
                          type="text"
                          variant="outlined"
                          InputLabelProps={{
                            shrink: !!values.customerNumber,
                          }}
                          style={{ width: 200, margin: 0 }}
                          my={2}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="invoiceNumber"
                          label="Invoice number"
                          value={values.invoiceNumber}
                          error={Boolean(touched.invoiceNumber && errors.invoiceNumber)}
                          helperText={touched.invoiceNumber && errors.invoiceNumber}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            handleChange(e);
                            const newInvoiceNumber = e.target.value;
                            setViewCustomerInvoiceDialog((prevState) => ({
                              ...prevState,
                              params: {
                                ...prevState.params,
                                invoiceNumber: newInvoiceNumber,
                              },
                            }));
                          }}
                          type="text"
                          variant="outlined"
                          InputLabelProps={{
                            shrink: !!values.invoiceNumber,
                          }}
                          my={2}
                          style={{ width: 200, margin: 0 }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="invoiceDate"
                          name="invoiceDate"
                          label="Invoice date"
                          type="date"
                          value={values.invoiceDate}
                          onChange={(e) => {
                            handleChange(e);
                            const newInvoiceDate = e.target.value;
                            setViewCustomerInvoiceDialog((prevState) => ({
                              ...prevState,
                              params: {
                                ...prevState.params,
                                invoiceDate: newInvoiceDate,
                              },
                            }));
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={Boolean(touched.invoiceDate && errors.invoiceDate)}
                          helperText={touched.invoiceDate && errors.invoiceDate ? errors.invoiceDate : ""}
                          variant="outlined"
                          my={1}
                          style={{ width: 200, margin: 0 }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <SelectWrapper
                          name="invoiceStateIdStatic"
                          label="Invoice State"
                          value={values.invoiceStateIdStatic}
                          onChange={(e) => {
                            handleChange(e);
                            const newinvoiceStateIdStatic = e.target.value;
                            setViewCustomerInvoiceDialog((prevState) => ({
                              ...prevState,
                              params: {
                                ...prevState.params,
                                invoiceStateId: newinvoiceStateIdStatic,
                              },
                            }));
                          }}
                          options={props.invoiceStateArr.map((item) => ({
                            key: item.invoiceStateId,
                            value: item.invoiceStateDescription,
                          }))}
                          my={1}
                          style={{ width: 200, margin: 0 }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <SelectWrapper
                          name="supplierId"
                          label="Leverancier"
                          value={values.supplierId}
                          options={filteredSuppliers.map((supplier) => ({
                            key: supplier.supplierId,
                            value: supplier.supplierName,
                          }))}
                          onChange={(event) => handleSupplierChange(event, setFieldValue)}
                          my={1}
                          style={{ width: 200, margin: 0 }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <SelectWrapper
                          name="countryCode"
                          label="Country"
                          value={values.countryCode}
                          onChange={(e) => {
                            handleChange(e);
                            setCurrentCountryCode(e.target.value);
                            setFieldValue("countryCode", e.target.value);
                          }}
                          options={props.countryArr.map((country) => ({
                            key: country.countryId,
                            value: country.countryCode,
                          }))}
                          my={1}
                          style={{ width: 200, margin: 0 }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <SelectWrapper
                          name="claimProductTypeCode"
                          label="Product Type"
                          value={values.claimProductTypeCode}
                          onChange={(e) => {
                            handleChange(e);
                            handleSelectedProductCatagoryChange(e, setFieldValue);
                          }}
                          options={groupedProductCodes}
                          error={Boolean(touched.claimProductTypeCode && errors.claimProductTypeCode)}
                          helperText={touched.claimProductTypeCode && errors.claimProductTypeCode ? errors.claimProductTypeCode : ""}
                          my={1}
                          style={{ width: 200, margin: 0 }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <SelectWrapper
                          name="claimProductSubCodeId"
                          label="Product SubCode"
                          value={values.claimProductSubCodeId}
                          options={groupedProductSubCodes}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          error={Boolean(touched.claimProductSubCodeId && errors.claimProductSubCodeId)}
                          helperText={
                            touched.claimProductSubCodeId && errors.claimProductSubCodeId ? errors.claimProductSubCodeId : ""
                          }
                          my={1}
                          style={{ width: 200, margin: 0 }}
                        />
                      </Grid>
                      {showExtraFields && (
                        <>
                          <Grid item xs={12}>
                            <SelectWrapper
                              name="languageCode"
                              label="FreeText Language"
                              value={values.languageCode}
                              options={customSelectableLanguages}
                              onChange={(e) => {
                                handleChange(e);
                                const newlanguageCode = e.target.value;
                                setViewCustomerInvoiceDialog((prevState) => ({
                                  ...prevState,
                                  params: {
                                    ...prevState.params,
                                    languageCode: newlanguageCode,
                                  },
                                }));
                              }}
                              my={1}
                              style={{ width: 200, margin: 0 }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              name="freeText"
                              label="FreeText"
                              value={values.freeText}
                              error={Boolean(touched.freeText && errors.freeText)}
                              fullWidth
                              helperText={touched.freeText && errors.freeText ? errors.freeText : ""}
                              onBlur={handleBlur}
                              onChange={(e) => {
                                handleChange(e);
                                const newfreeText = e.target.value;
                                setViewCustomerInvoiceDialog((prevState) => ({
                                  ...prevState,
                                  params: {
                                    ...prevState.params,
                                    freeText: newfreeText,
                                  },
                                }));
                              }}
                              type="text"
                              variant="outlined"
                              my={1}
                              style={{ width: 200, margin: 0 }}
                            />
                          </Grid>
                        </>
                      )}

                      <Grid item xs={12}>
                        <SelectWrapper
                          name="currencyId"
                          label="Currency"
                          value={values.currencyId}
                          onChange={handleChange}
                          options={props.currencyArr.map((currency) => ({
                            key: currency.currencyId,
                            value: currency.isoCode,
                          }))}
                          my={1}
                          style={{ width: 200, margin: 0 }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="invoiceGrossAmount"
                          label="Invoice net amount"
                          value={values.invoiceGrossAmount || ""}
                          error={Boolean(touched.invoiceGrossAmount && errors.invoiceGrossAmount)}
                          fullWidth
                          helperText={touched.invoiceGrossAmount && errors.invoiceGrossAmount ? errors.invoiceGrossAmount : ""}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            handleChange(e);
                            const newinvoiceGrossAmount = e.target.value;
                            setViewCustomerInvoiceDialog((prevState) => ({
                              ...prevState,
                              params: {
                                ...prevState.params,
                                totalExcludingTax: newinvoiceGrossAmount,
                              },
                            }));
                          }}
                          type="number"
                          variant="outlined"
                          InputLabelProps={{
                            shrink: !!values.invoiceGrossAmount,
                          }}
                          my={1}
                          style={{ width: 200, margin: 0 }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          name="invoiceVATAmountLocalCurrency"
                          label="Invoice VAT amount"
                          value={values.invoiceVATAmountLocalCurrency || ""}
                          error={Boolean(touched.invoiceVATAmountLocalCurrency && errors.invoiceVATAmountLocalCurrency)}
                          fullWidth
                          helperText={
                            touched.invoiceVATAmountLocalCurrency && errors.invoiceVATAmountLocalCurrency
                              ? errors.invoiceVATAmountLocalCurrency
                              : ""
                          }
                          onBlur={handleBlur}
                          onChange={(e) => {
                            handleChange(e);
                            const newinvoiceVATAmountLocalCurrency = e.target.value;
                            setViewCustomerInvoiceDialog((prevState) => ({
                              ...prevState,
                              params: {
                                ...prevState.params,
                                totalTax: newinvoiceVATAmountLocalCurrency,
                              },
                            }));
                          }}
                          type="number"
                          variant="outlined"
                          InputLabelProps={{
                            shrink: !!values.invoiceVATAmountLocalCurrency,
                          }}
                          my={1}
                          style={{ width: 200, margin: 0 }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* PDF-pane (rechts) */}
                  <Grid item sx={{ flexGrow: 1 }}>
                    <Box style={{ width: "100%", height: "100%", overflow: "auto", display: "flex", flexDirection: "column" }}>
                      {fileUrl === "" ? (
                        <Typography
                          sx={{
                            fontSize: "21px",
                            mt: 2,
                            pt: "20px",
                            pb: "20px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            border: 1,
                            borderColor: "lightgray",
                            width: "100%",
                            height: "100%",
                            boxSizing: "border-box",
                          }}
                        >
                          <CircularProgress size={30} sx={{ mr: 2 }} />
                          Loading file, please wait!
                        </Typography>
                      ) : fileUrl === "NoFile" || fileUrl === null ? (
                        <Typography
                          sx={{
                            fontSize: "21px",
                            mt: 2,
                            pt: "20px",
                            pb: "20px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            border: 1,
                            borderColor: "lightgray",
                            width: "100%",
                            height: "100%",
                            boxSizing: "border-box",
                          }}
                        >
                          No files are currently attached to this invoice.
                        </Typography>
                      ) : (
                        <Box style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", flexGrow: 1 }}>
                          <Dialog open={showConfirmationDialog} onClose={handleConfirmationDialogClose}>
                            <DialogTitle>Confirmation</DialogTitle>
                            <DialogContent>
                              <DialogContentText>Are you sure you want to delete the invoice copy?</DialogContentText>
                            </DialogContent>
                            <DialogActions>
                              <Button onClick={handleConfirmationDialogClose} color="primary">
                                Cancel
                              </Button>
                            </DialogActions>
                          </Dialog>
                          <object
                            width="100%"
                            height="100%"
                            data={fileUrl}
                            type="application/pdf"
                            style={{
                              marginTop: "8px",
                              flex: 1,
                              width: "100%",
                              height: "100%",
                              boxSizing: "border-box",
                            }}
                          >
                            <iframe title="file" src={fileUrl + "&embedded=true"} style={{ width: "100%", height: "100vh" }} />
                          </object>
                        </Box>
                      )}
                    </Box>
                  </Grid>
                </Grid>

                {/* Update-knop */}
                <Box display="flex" justifyContent="flex-start" mt={2}>
                  <Button type="submit" variant="contained" color="primary" disabled={isSubmitting} sx={{ mr: 4 }}>
                    Update Invoice
                  </Button>
                </Box>
              </form>
            ) : (
              // Enkel de PDF tonen als activeTab == 3
              <Grid container spacing={6} sx={{ flexGrow: 1 }}>
                <Grid item sx={{ flexGrow: 1 }}>
                  <Box style={{ width: "100%", height: "100%", overflow: "auto", display: "flex", flexDirection: "column" }}>
                    {fileUrl === "" ? (
                      <Typography
                        sx={{
                          fontSize: "21px",
                          mt: 2,
                          pt: "20px",
                          pb: "20px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          border: 1,
                          borderColor: "lightgray",
                          width: "100%",
                          height: "100%",
                          boxSizing: "border-box",
                        }}
                      >
                        <CircularProgress size={30} sx={{ mr: 2 }} />
                        Loading file, please wait!
                      </Typography>
                    ) : fileUrl === "NoFile" || fileUrl === null ? (
                      <Typography
                        sx={{
                          fontSize: "21px",
                          mt: 2,
                          pt: "20px",
                          pb: "20px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          border: 1,
                          borderColor: "lightgray",
                          width: "100%",
                          height: "100%",
                          boxSizing: "border-box",
                        }}
                      >
                        No files are currently attached to this invoice.
                      </Typography>
                    ) : (
                      <Box style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", flexGrow: 1 }}>
                        <Dialog open={showConfirmationDialog} onClose={handleConfirmationDialogClose}>
                          <DialogTitle>Confirmation</DialogTitle>
                          <DialogContent>
                            <DialogContentText>Are you sure you want to delete the invoice copy?</DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={handleConfirmationDialogClose} color="primary">
                              Cancel
                            </Button>
                          </DialogActions>
                        </Dialog>
                        <object
                          width="100%"
                          height="100%"
                          data={fileUrl}
                          type="application/pdf"
                          style={{
                            marginTop: "8px",
                            flex: 1,
                            width: "100%",
                            height: "100%",
                            boxSizing: "border-box",
                          }}
                        >
                          <iframe title="file" src={fileUrl + "&embedded=true"} style={{ width: "100%", height: "100vh" }} />
                        </object>
                      </Box>
                    )}
                  </Box>
                </Grid>
              </Grid>
            )}
          </CardContent>
        </Card>
      )}
    </Formik>
  );
}
