import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Grid, Box, Card as MuiCard, CardContent as MuiCardContent, Typography, Divider, IconButton } from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { spacing } from "@mui/system";
import Notification from "../../components/Notification";
import ConfirmDialog from "../../components/ConfirmDialog";
import apiUtils from "../../utils/apiUtils";
import ViewPortalLoginDialog from "../../components/ViewPortalLoginDialog";
import ViewSupplierPortalLoginDialog from "../../components/ViewSupplierPortalLoginDialog";
import ViewEuVatPortalLoginDialog from "../../components/ViewEuVatPortalLoginsDialog";
import ViewOtherPortalLoginDialog from "../../components/ViewOtherPortalLoginDialog";
import AddCustomerPortalLogin from "../../components/AddCustomerPortalLogin";
import AddSupplierPortalLogin from "../../components/AddSupplierPortalLogin";
import AddEuVatPortalLogin from "../../components/AddEuVatPortalLogin";
import AddOtherPortalLogin from "../../components/AddOtherPortalLogin";
import { useCallback } from "react";
import DataGridExport from "../../components/DataGridExport";
// import moment from "moment";
// import { de } from "date-fns/locale";

const Card = styled(MuiCard)(spacing);
const CardContent = styled(MuiCardContent)(spacing);

const CustomerLogins = ({ customerId }) => {
  const supplierPortalData = apiUtils.useApiData("/api/v1/supplierPortalInfo");

  const columnsPortalLogins = [
    {
      field: "id",
      headerName: "Portal Login ID",
      width: 50,
      hide: true,
    },
    { field: "firstname", headerName: "Firstname", flex: 0.5 },
    { field: "lastname", headerName: "Lastname", flex: 0.5 },
    {
      field: "actions",
      headerName: "Actions",
      width: 130,
      renderCell: (params) => {
        return (
          <Box>
            <IconButton
              onClick={(e) => {
                setConfirmDialog({
                  isOpen: true,
                  title: "Delete Portal login",
                  subTitle:
                    "Portal Login will also be removed from Azure Entra ID. Are you sure you want to delete this Portal Login?",
                  onConfirm: () => {
                    onDeletePortalLoginConfirmed(e, params.row);
                  },
                });
              }}
            >
              <DeleteIcon />
            </IconButton>
            <IconButton
              onClick={(e) => {
                setViewPortalLoginDialog({
                  isOpen: true,
                  title: "Edit portal login",
                  params: params.row,
                  customerId: customerId,
                  test: [],
                  subTitle: "Edit " + params.row.iban + "?",
                  onConfirm: () => {
                    onEditConfirmed(e, params.row);
                  },
                });
              }}
            >
              <VisibilityIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const columnsSupplierPortalLogins = [
    {
      field: "customerLoginId",
      headerName: "Portal Login ID",
      width: 50,
      hide: true,
    },
    {
      field: "supplierPortalId",
      headerName: "Supplier Name",
      flex: 1,
      valueGetter: (params) => {
        const supplier = supplierPortalData.find((s) => s.supplierPortalId === params.row.supplierPortalId);
        return supplier ? supplier.name : "";
      },
    },
    { field: "username", headerName: "Username", flex: 0.5 },
    {
      field: "actions",
      headerName: "Actions",
      width: 130,
      renderCell: (params) => {
        return (
          <Box>
            {/* Delete actie */}
            <IconButton
              onClick={(e) => {
                setConfirmDialog({
                  isOpen: true,
                  title: "Delete Portal Login",
                  subTitle: "Are you sure you want to delete this login?",
                  onConfirm: () => onDeleteLoginConfirmed(e, params.row),
                });
              }}
            >
              <DeleteIcon />
            </IconButton>
            {/* Edit actie (via SupplierPortalLogin dialog) */}
            <IconButton
              onClick={(e) => {
                setViewSupplierPortalLoginDialog({
                  isOpen: true,
                  title: "Edit Portal Login",
                  params: params.row,
                  customerId: customerId,
                });
              }}
            >
              <VisibilityIcon />
            </IconButton>
            {/* Open URL in nieuwe tab */}
            <IconButton
              onClick={() => {
                const supplier = supplierPortalData.find((s) => s.supplierPortalId === params.row.supplierPortalId);
                if (supplier && supplier.url) {
                  window.open(supplier.url, "_blank");
                }
              }}
            >
              <OpenInNewIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const columnsEuVatPortalLogins = [
    {
      field: "customerLoginId",
      headerName: "Portal Login ID",
      width: 50,
      hide: true,
    },
    // {
    //   field: "supplierPortalId",
    //   headerName: "Supplier Name",
    //   flex: 1,
    //   valueGetter: (params) => {
    //     const supplier = supplierPortalData.find((s) => s.supplierPortalId === params.row.supplierPortalId);
    //     return supplier ? supplier.name : "";
    //   },
    // },
    { field: "username", headerName: "Username", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      width: 130,
      renderCell: (params) => {
        return (
          <Box>
            {/* Delete actie */}
            <IconButton
              onClick={(e) => {
                setConfirmDialog({
                  isOpen: true,
                  title: "Delete Portal Login",
                  subTitle: "Are you sure you want to delete this login?",
                  onConfirm: () => onDeleteLoginConfirmed(e, params.row),
                });
              }}
            >
              <DeleteIcon />
            </IconButton>
            {/* Edit actie (via SupplierPortalLogin dialog) */}
            <IconButton
              onClick={(e) => {
                setViewEuVatPortalLoginDialog({
                  isOpen: true,
                  title: "Edit EU VAT Portal Login",
                  params: params.row,
                  customerId: customerId,
                });
              }}
            >
              <VisibilityIcon />
            </IconButton>
            {/* Open URL in a new tab */}
            <IconButton onClick={() => window.open("https://eubtw.belastingdienst.nl/netp/", "_blank")}>
              <OpenInNewIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const columnsOtherPortalLogins = [
    {
      field: "customerLoginId",
      headerName: "Portal Login ID",
      width: 50,
      hide: true,
    },
    // {
    //   field: "supplierPortalId",
    //   headerName: "Supplier Name",
    //   flex: 1,
    //   valueGetter: (params) => {
    //     const supplier = supplierPortalData.find((s) => s.supplierPortalId === params.row.supplierPortalId);
    //     return supplier ? supplier.name : "";
    //   },
    // },
    { field: "username", headerName: "Username", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      width: 130,
      renderCell: (params) => {
        return (
          <Box>
            {/* Delete actie */}
            <IconButton
              onClick={(e) => {
                setConfirmDialog({
                  isOpen: true,
                  title: "Delete Portal Login",
                  subTitle: "Are you sure you want to delete this login?",
                  onConfirm: () => onDeleteLoginConfirmed(e, params.row),
                });
              }}
            >
              <DeleteIcon />
            </IconButton>
            {/* Edit actie (via SupplierPortalLogin dialog) */}
            <IconButton
              onClick={(e) => {
                setViewOtherPortalLoginDialog({
                  isOpen: true,
                  title: "Edit Other Portal Login",
                  params: params.row,
                  customerId: customerId,
                });
              }}
            >
              <VisibilityIcon />
            </IconButton>
            {/* Open URL in nieuwe tab */}
            <IconButton
              onClick={() => window.open(params.row.url, "_blank")}
              disabled={!params.row.url || params.row.url.trim() === ""}
            >
              <OpenInNewIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const [tablePortalLogins, setTablePortalLogins] = useState([]);
  const [loadingPortalLogins, setLoadingPortalLogins] = useState(false);

  const [supplierPortalLogins, setSupplierPortalLogins] = useState([]);
  const [euVatPortalLogins, setEuVatPortalLogins] = useState([]);
  const [otherPortalLogins, setOtherPortalLogins] = useState([]);
  const [loading, setLoading] = useState(false);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [viewPortalLoginDialog, setViewPortalLoginDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    test: [],
  });

  const [viewSupplierPortalLoginDialog, setViewSupplierPortalLoginDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    test: [],
  });

  const [viewEuVatPortalLoginDialog, setViewEuVatPortalLoginDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    test: [],
  });

  const [viewOtherPortalLoginDialog, setViewOtherPortalLoginDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    test: [],
  });

  const fetchPortalLogins = useCallback(async () => {
    try {
      setLoadingPortalLogins(true);
      const data = await apiUtils.fetchData(`/api/v1/AppUsersByEntityId/${customerId}`);
      console.log(data);
      setTablePortalLogins(data);
      setLoadingPortalLogins(false);
    } catch (error) {
      console.error(error);
      setLoadingPortalLogins(false);
      // Handle error here...
    }
  }, [customerId]);

  const fetchSupplierPortalLogins = useCallback(async () => {
    try {
      setLoadingPortalLogins(true);
      const data = await apiUtils.fetchData(`/api/v1/customerLoginsByEntityId/${customerId}`);
      // Filter to include only records where customerLoginTypeId is 1 (SupplierPortal)
      const supplierPortalLoginfilteredData = data.filter((login) => login.customerLoginTypeId === 1);
      const euVatPortalLoginfilteredData = data.filter((login) => login.customerLoginTypeId === 2);
      const otherPortalLoginfilteredData = data.filter((login) => login.customerLoginTypeId === 4);

      setSupplierPortalLogins(supplierPortalLoginfilteredData);
      setEuVatPortalLogins(euVatPortalLoginfilteredData);
      setOtherPortalLogins(otherPortalLoginfilteredData);
      setLoadingPortalLogins(false);
    } catch (error) {
      console.error(error);
      setLoadingPortalLogins(false);
    }
  }, [customerId]);

  const onDeleteLoginConfirmed = async (e, row) => {
    try {
      // Verwijder het record via de API
      const result = await apiUtils.deleteData(`/api/v1/customerLogins/${row.customerLoginId}`);
      if (result) {
        setNotify({ isOpen: true, message: "Deleted Successfully", type: "success" });
      }
      fetchSupplierPortalLogins();
    } catch (error) {
      console.error(error);
      setNotify({ isOpen: true, message: "Error deleting login", type: "error" });
    }
    setConfirmDialog({ ...confirmDialog, isOpen: false });
  };

  useEffect(() => {
    fetchPortalLogins();
  }, [fetchPortalLogins]);

  useEffect(() => {
    fetchSupplierPortalLogins();
  }, [fetchSupplierPortalLogins]);

  if (loadingPortalLogins) {
    return <div>Loading...</div>;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  const onDeletePortalLoginConfirmed = async (e, params) => {
    try {
      const deleteResult = await apiUtils.deleteData(`/api/v1/DeleteUser/${params.id}`);
      console.log(deleteResult);
      if (deleteResult) {
        await apiUtils.deleteData(`/api/v1/PortalLogins/${params.portalLoginId}`);
        setNotify({
          isOpen: true,
          message: "Deleted Successfully",
          type: "success",
        });
      }
      fetchPortalLogins();
    } catch (error) {
      console.error(error);
      // handle error here...
    }

    setConfirmDialog({ ...confirmDialog, isOpen: false });
  };

  const onEditConfirmed = (e, params) => {
    setViewPortalLoginDialog({ ...viewPortalLoginDialog, isOpen: false });
  };

  return (
    <React.Fragment>
      <Helmet title="Customer Financials" />
      <Divider my={6} />
      <Grid item xs={12} md={6}>
        {/* <FormDialog /> */}
      </Grid>
      <Card mb={0}>
        <CardContent pb={1} sx={{ marginBottom: 0 }}>
          {/* <Typography variant="h6" gutterBottom>
            Customer Logins
          </Typography> */}
        </CardContent>
        <Grid container spacing={0} mt={0}>
          <Notification notify={notify} setNotify={setNotify} />
          <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
          <ViewPortalLoginDialog
            viewPortalLoginDialog={viewPortalLoginDialog}
            setViewPortalLoginDialog={setViewPortalLoginDialog}
            onPortalLoginUpdate={fetchPortalLogins}
          />
          <ViewSupplierPortalLoginDialog
            viewSupplierPortalLoginDialog={viewSupplierPortalLoginDialog}
            setViewSupplierPortalLoginDialog={setViewSupplierPortalLoginDialog}
            onSupplierPortalLoginUpdate={fetchSupplierPortalLogins}
          />
          <ViewEuVatPortalLoginDialog
            viewEuVatPortalLoginDialog={viewEuVatPortalLoginDialog}
            setViewEuVatPortalLoginDialog={setViewEuVatPortalLoginDialog}
            onEuVatPortalLoginUpdate={fetchSupplierPortalLogins}
          />
          <ViewOtherPortalLoginDialog
            viewOtherPortalLoginDialog={viewOtherPortalLoginDialog}
            setViewOtherPortalLoginDialog={setViewOtherPortalLoginDialog}
            onOtherPortalLoginUpdate={fetchSupplierPortalLogins}
          />
          <Grid xs={5}>
            <Typography variant="h6" gutterBottom>
              Supplier Portal Logins
            </Typography>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs={6} style={{ margin: 0, padding: 0 }}>
                <AddSupplierPortalLogin onDataRefresh={fetchSupplierPortalLogins} customerId={customerId} />
              </Grid>
              <Grid item xs={6} style={{ margin: 0, padding: 0 }}>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <DataGridExport tableData={supplierPortalLogins} columns={columnsSupplierPortalLogins} />
                </div>
              </Grid>
            </Grid>
            <DataGrid
              getRowId={(row) => row.customerLoginId}
              disableColumnFilter
              disableSelectionOnClick
              disableColumnSelector
              components={{ Toolbar: GridToolbar }}
              density="compact"
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: false },
                  printOptions: { disableToolbarButton: false },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              rows={supplierPortalLogins}
              columns={columnsSupplierPortalLogins}
              pageSize={10}
              sx={{
                height: 307,
                width: "100%",
                borderRadius: 1,
                backgroundColor: "background.paper",
                boxShadow: 2,
                "& .MuiDataGrid-cell:hover": {
                  color: "primary.main",
                },
                marginTop: 0,
              }}
            />
          </Grid>
          <Grid xs={2}></Grid>
          <Grid xs={5}>
            <Typography variant="h6" gutterBottom>
              Customer Portal Logins
            </Typography>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs={6} style={{ margin: 0, padding: 0 }}>
                <AddCustomerPortalLogin onDataRefresh={fetchPortalLogins} customerId={customerId} />
              </Grid>
              <Grid item xs={6} style={{ margin: 0, padding: 0 }}>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <DataGridExport tableData={tablePortalLogins} columns={columnsPortalLogins} />
                </div>
              </Grid>
            </Grid>
            <DataGrid
              getRowId={(row) => row.id}
              disableColumnFilter
              disableSelectionOnClick
              disableColumnSelector
              components={{ Toolbar: GridToolbar }}
              density="compact"
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: false },
                  printOptions: { disableToolbarButton: false },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              rows={tablePortalLogins}
              columns={columnsPortalLogins}
              pageSize={10}
              sx={{
                height: 307,
                width: "100%",
                borderRadius: 1,
                backgroundColor: "background.paper",
                boxShadow: 2,
                "& .MuiDataGrid-cell:hover": {
                  color: "primary.main",
                },
                marginTop: 0,
              }}
            />
          </Grid>
          <Grid xs={5} style={{ marginTop: 20 }}>
            <Typography variant="h6" gutterBottom>
              EU VAT Logins
            </Typography>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs={6} style={{ margin: 0, padding: 0 }}>
                <AddEuVatPortalLogin onDataRefresh={fetchSupplierPortalLogins} customerId={customerId} />
              </Grid>
              <Grid item xs={6} style={{ margin: 0, padding: 0 }}>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <DataGridExport tableData={euVatPortalLogins} columns={columnsEuVatPortalLogins} />
                </div>
              </Grid>
            </Grid>
            <DataGrid
              getRowId={(row) => row.customerLoginId}
              disableColumnFilter
              disableSelectionOnClick
              disableColumnSelector
              components={{ Toolbar: GridToolbar }}
              density="compact"
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: false },
                  printOptions: { disableToolbarButton: false },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              rows={euVatPortalLogins}
              columns={columnsEuVatPortalLogins}
              pageSize={10}
              sx={{
                height: 307,
                width: "100%",
                borderRadius: 1,
                backgroundColor: "background.paper",
                boxShadow: 2,
                "& .MuiDataGrid-cell:hover": {
                  color: "primary.main",
                },
                marginTop: 0,
              }}
            />
          </Grid>
          <Grid xs={2} style={{ marginTop: 20 }}></Grid>
          <Grid xs={5} style={{ marginTop: 20 }}>
            <Typography variant="h6" gutterBottom>
              Other Logins
            </Typography>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs={6} style={{ margin: 0, padding: 0 }}>
                <AddOtherPortalLogin onDataRefresh={fetchSupplierPortalLogins} customerId={customerId} />
              </Grid>
              <Grid item xs={6} style={{ margin: 0, padding: 0 }}>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <DataGridExport tableData={otherPortalLogins} columns={columnsOtherPortalLogins} />
                </div>
              </Grid>
            </Grid>
            <DataGrid
              getRowId={(row) => row.customerLoginId}
              disableColumnFilter
              disableSelectionOnClick
              disableColumnSelector
              components={{ Toolbar: GridToolbar }}
              density="compact"
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: false },
                  printOptions: { disableToolbarButton: false },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              rows={otherPortalLogins}
              columns={columnsOtherPortalLogins}
              pageSize={10}
              sx={{
                height: 307,
                width: "100%",
                borderRadius: 1,
                backgroundColor: "background.paper",
                boxShadow: 2,
                "& .MuiDataGrid-cell:hover": {
                  color: "primary.main",
                },
                marginTop: 0,
              }}
            />
          </Grid>
        </Grid>
      </Card>
    </React.Fragment>
  );
};

export default CustomerLogins;
