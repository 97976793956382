import React, { useState, useEffect, useCallback, useRef } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { DataGrid, GridToolbar, GridFooterContainer, GridPagination } from "@mui/x-data-grid";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider,
  Button,
  IconButton,
  CircularProgress,
  Tabs,
  Tab,
  FormControl,
  // InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { spacing } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import ViewCustomerInvoiceUploadDialog from "../../components/ViewCustomerInvoiceUploadDialog";
import apiUtils from "../../utils/apiUtils";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { LinearProgress, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
// import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
const CardContent = styled(MuiCardContent)(spacing);

const CustomerDataGrid = () => {
  const [tableData, setTableData] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [viewCustomerInvoiceDialog, setViewCustomerInvoiceDialog] = useState({
    isOpen: false,
    downloadFile: false,
    params: {},
  });
  const [rightPaneSize, setRightPaneSize] = useState(0); // Initial size is 0 (hidden)
  const [lastRightPaneSize, setLastRightPaneSize] = useState(0.5); // Default to 50%
  const [highlightedRowId, setHighlightedRowId] = useState(null);
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);

  const containerRef = useRef(null); // Reference to the container div

  const [uploading, setUploading] = useState(false);
  const [uploadResults, setUploadResults] = useState([]);
  // const [fileProgress, setFileProgress] = useState({});
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  const supplierData = apiUtils.useApiData("/api/v1/suppliers");
  const currencyData = apiUtils.useApiData("/api/v1/currencies");
  const productData = apiUtils.useApiData("/api/v1/productTypes");
  const invoiceStateData = apiUtils.useApiData("/api/v1/invoiceStates");
  const countryData = apiUtils.useApiData("/api/v1/countries");
  const customerData = apiUtils.useApiData("/api/v1/entities");

  const fetchCustomerFiles = useCallback(async () => {
    try {
      const response = await axios.get("/api/v1/CustomerFile");
      setTableData(response.data);
    } catch (error) {
      console.error("Error fetching customer files:", error);
    }
  }, []);

  useEffect(() => {
    fetchCustomerFiles();
  }, [fetchCustomerFiles]);

  const columns = [
    { field: "fileId", headerName: "File ID", flex: 0.5, sortable: true, hide: true },
    {
      field: "entityId",
      headerName: "Customer Number",
      flex: 1.5,
      sortable: true,
      valueGetter: (params) => {
        const entity = customerData.find((item) => item.entityId === params.row.entityId);
        return entity ? entity.entityNumber : "Unknown";
      },
    },
    { field: "fileName", headerName: "File Name", flex: 1.5, sortable: true },
    { field: "mimeType", headerName: "Mime Type", flex: 0.5, sortable: true },
    {
      field: "creationDate",
      headerName: "Upload Date",
      flex: 1,
      sortable: true,
      valueGetter: (params) => new Date(params.value),
      valueFormatter: (params) => {
        const date = new Date(params.value);
        return new Intl.DateTimeFormat("nl-NL", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }).format(date);
      },
    },
    ...(tabValue === 1
      ? [
          {
            field: "processDate",
            headerName: "Process Date",
            flex: 1,
            sortable: true,
            valueGetter: (params) => new Date(params.value),
            valueFormatter: (params) => {
              const date = new Date(params.value);
              return new Intl.DateTimeFormat("nl-NL", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              }).format(date);
            },
          },
        ]
      : []),
    { field: "fileSize", headerName: "File Size", flex: 1, sortable: true },
  ];

  // Shared function to open row details
  const openRowDetails = (params) => {
    setHighlightedRowId(params.id);
    setViewCustomerInvoiceDialog({
      isOpen: true,
      downloadFile: true,
      params: params.row,
    });
    setRightPaneSize(lastRightPaneSize); // Open the right pane with the last size
  };

  const handleRowClick = (params, event) => {
    event.defaultMuiPrevented = true; // Prevent default selection behavior
    openRowDetails(params);
  };

  const handleUploadClick = () => {
    setIsUploadDialogOpen(true); // Open the confirmation dialog
  };

  const [setProcessedByDwsInProgress, setSetProcessedByDwsInProgress] = useState(false);

  const handleProcessedByDWSClick = async () => {
    setSetProcessedByDwsInProgress(true);
    // const processedByDWS = {
    //   processedByDWS: 1,
    //   processDate: moment().format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z",
    // };

    const processedFiles = selectedFiles.map(async (fileId) => {
      const fileData = tableData.find((file) => file.fileId === fileId);
      if (!fileData) return;

      try {
        const updateCustomerFile = await axios.put(`/api/v1/CustomerFileSetProcessedByDws/${fileId}`);
        console.log("Updated Customer File:", updateCustomerFile.data);

        // Update the tableData to remove the uploaded file
        setTableData((prevData) =>
          prevData.map((file) =>
            file.fileId === fileId
              ? { ...file, processedByDWS: 1, processDate: moment().format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z" }
              : file
          )
        );
      } catch (error) {
        console.error(`Error updating file with ID ${fileId}:`, error);
      }
    });

    setSetProcessedByDwsInProgress(false);
    await Promise.all(processedFiles);
  };

  // Modify the handleConfirmUpload function
  const handleConfirmUpload = async () => {
    setIsUploadDialogOpen(false); // Close the dialog
    setUploading(true);
    const results = [];

    try {
      // Create a new Batch
      const batchInfo = {
        uploadedBy: 1, // Replace with actual user ID if available
        // Add any additional batch metadata if required
      };

      const batchResponse = await axios.post("/api/v1/OCRBatch", batchInfo);
      const batchId = batchResponse.data.batchId;

      console.log("Created Batch ID:", batchId);

      // Prepare upload promises
      const uploadPromises = selectedFiles.map(async (fileId) => {
        const fileData = tableData.find((file) => file.fileId === fileId);
        if (!fileData) return;

        const fileInfo = {
          documentType: 1,
          processedByDWS: 1,
          deleted: 0,
          fileName: fileData.fileName,
          mimeType: fileData.mimeType,
          fileSize: fileData.fileSize,
          batchId: batchId,
          uploadDate: moment().format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z",
          uploadedBy: "", // Replace with actual user ID if available
        };

        try {
          const fileResponse = await axios.post("/api/v1/OCRFile", fileInfo);
          const newFileId = fileResponse.data.fileId;

          // Fetch the Blob from the download endpoint
          const blobResponse = await axios.get(`/api/v1/AzureCustomerBlobStorage/${fileId}`, {
            responseType: "blob",
          });
          const blob = blobResponse.data;

          // Append to FormData
          const formData = new FormData();
          formData.append("file", blob, newFileId + ".pdf");

          // Upload to OCR system
          const uploadResponse = await axios.post("/api/v1/azureocrblobstorage", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            // onUploadProgress: (progressEvent) => {
            //   const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            //   setFileProgress((prev) => ({ ...prev, [fileId]: progress }));
            // },
          });

          if (uploadResponse.status === 200) {
            const updateCustomerFile = await axios.put(`/api/v1/CustomerFileSetProcessedByDws/${fileId}`);
            console.log("Updated Customer File:", updateCustomerFile.data);

            // if (updateCustomerFile.status === 200) {
            //   // Update the tableData to remove the uploaded file
            //   setTableData((prevData) => prevData.filter((file) => file.fileId !== fileId));
            //   results.push({ file: fileData.fileName, status: "Uploaded successfully" });
            // }

            // Update the tableData to remove the uploaded file
            setTableData((prevData) => prevData.map((file) => (file.fileId === fileId ? { ...file, processedByDWS: 1 } : file)));

            results.push({ file: fileData.fileName, status: "Uploaded successfully" });
          }
        } catch (error) {
          console.error(`Upload failed for file ID ${fileId}:`, error);
          results.push({ file: fileData.fileName, status: `Error: ${error.response?.data || error.message}` });
        }
      });

      // Execute all upload promises
      await Promise.all(uploadPromises);

      setUploadResults(results);
      setUploading(false);
      setSelectedFiles([]);
    } catch (batchError) {
      console.error("Batch creation failed:", batchError);
      // Handle batch creation error
      setUploading(false);
      setUploadResults([{ file: null, status: `Batch Error: ${batchError.response?.data || batchError.message}` }]);
    }

    // Optionally, refresh the table data
    // fetchCustomerFiles();
  };

  const handleCancelUpload = () => {
    setIsUploadDialogOpen(false); // Close the dialog without doing anything
  };

  const handleDownloadClick = async () => {
    const downloadPromises = selectedFiles.map(async (fileId) => {
      const fileData = tableData.find((file) => file.fileId === fileId);
      if (!fileData) return;

      const { fileName } = fileData;

      try {
        const response = await axios({
          url: `/api/v1/AzureCustomerBlobStorage/${fileId}`,
          method: "GET",
          responseType: "blob",
        });
        const href = URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (error) {
        console.error(`Error downloading file with ID ${fileId}:`, error);
      }
    });

    await Promise.all(downloadPromises);
  };

  // Handle key presses for +, -, ArrowUp, and ArrowDown keys
  const handleCellKeyDown = (params, event) => {
    if (event.key === "+" || event.key === "-") {
      event.preventDefault(); // Prevent default behavior

      const rowId = params.id;

      if (event.key === "+") {
        setSelectedFiles((prev) => {
          if (!prev.includes(rowId)) {
            return [...prev, rowId];
          }
          return prev;
        });
      } else if (event.key === "-") {
        setSelectedFiles((prev) => prev.filter((id) => id !== rowId));
      }
    } else if (event.key === "ArrowUp" || event.key === "ArrowDown") {
      // Delay execution to allow the focus to move to the next cell
      setTimeout(() => {
        const focusedCell = document.activeElement;
        const rowElement = focusedCell.closest("[data-id]");
        if (rowElement) {
          const rowId = rowElement.getAttribute("data-id");
          if (rowId && rowId !== highlightedRowId) {
            const rowData = tableData.find((row) => row.fileId.toString() === rowId);
            if (rowData) {
              const newParams = { id: rowId, row: rowData };
              openRowDetails(newParams);
            }
          }
        }
      }, 0);
    }
  };

  // Handle pane resize
  const handlePaneChange = (size) => {
    setRightPaneSize(size);
    if (size !== 0 && size !== 0) {
      setLastRightPaneSize(size);
    }
  };

  const [pageSize, setPageSize] = useState(15);
  const [userPageSize, setUserPageSize] = useState(null);

  useEffect(() => {
    const updatePageSize = () => {
      const gridHeight = containerRef.current ? containerRef.current.clientHeight : 0;
      const newPageSize = Math.floor((gridHeight - 305) / 37);
      if (userPageSize === null) {
        setUserPageSize(newPageSize);
        setPageSize(newPageSize);
      }
    };

    updatePageSize();
    window.addEventListener("resize", updatePageSize);
    return () => window.removeEventListener("resize", updatePageSize);
  }, [containerRef, userPageSize]);

  const CustomFooter = (props) => {
    return (
      <GridFooterContainer
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          p: 1,
          gap: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography variant="body2">Rows per page: </Typography>
          <FormControl variant="outlined" size="small" sx={{ minWidth: 70 }}>
            <Select
              id="page-size-select"
              value={userPageSize || ""}
              onChange={(e) => {
                setUserPageSize(e.target.value);
                setPageSize(e.target.value);
              }}
            >
              {[5, 10, 15, 20, 25, 50, 100].map((size) => (
                <MenuItem key={size} value={size}>
                  {size}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <GridPagination {...props} />
      </GridFooterContainer>
    );
  };

  return (
    <React.Fragment>
      <Helmet title="Customer Files" />
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Typography variant="h3" gutterBottom>
          Customer Files
        </Typography>
        <Breadcrumbs aria-label="Breadcrumb">
          <NavLink to="/">Dashboard</NavLink>
          <NavLink to="/">Pages</NavLink>
          <Typography>Customer Files</Typography>
        </Breadcrumbs>
      </Box>

      <Divider my={6} />

      <div ref={containerRef} style={{ height: "calc(100vh - 200px)", position: "relative" }}>
        <PanelGroup
          direction="horizontal"
          style={{ height: "100%" }}
          onUpdate={(sizes) => handlePaneChange(sizes[1])} // sizes array: [left, right]
          defaultSizes={[0.5, 0]} // Initial left pane at 50%, right pane hidden
        >
          {/* Left Panel */}
          <Panel minSize={0} style={{ overflow: "hidden" }}>
            <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
              <Tabs value={tabValue} onChange={(e, newValue) => setTabValue(newValue)} indicatorColor="primary" textColor="primary">
                <Tab label="Customer Uploads" />
                <Tab label="Historic Uploads" />
              </Tabs>
              <div style={{ flexGrow: 1, overflow: "auto" }}>
                <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                  <CardContent style={{ paddingBottom: 16 }}>
                    {tabValue === 0 ? (
                      <>
                        <Typography variant="h6" gutterBottom>
                          Customer Uploaded Files
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          In this table you can search and view customer uploaded files.
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Typography variant="h6" gutterBottom>
                          Processed Customer Uploaded Files
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          In this table you can search and view historic customer uploaded files.
                        </Typography>
                      </>
                    )}
                  </CardContent>
                  <div style={{ flexGrow: 1 }}>
                    <DataGrid
                      getRowId={(row) => row.fileId}
                      disableSelectionOnClick={true}
                      components={{ Toolbar: GridToolbar, Footer: CustomFooter }}
                      density="compact"
                      rowsPerPageOptions={[]} // Voeg dit toe
                      componentsProps={{
                        toolbar: {
                          csvOptions: { disableToolbarButton: false },
                          printOptions: { disableToolbarButton: false },
                          showQuickFilter: true,
                          quickFilterProps: { debounceMs: 250 },
                        },
                      }}
                      rows={(tabValue === 0
                        ? tableData.filter((row) => row.processedByDWS !== 1)
                        : tableData.filter((row) => row.processedByDWS === 1)
                      ).sort((a, b) => new Date(b.creationDate) - new Date(a.creationDate))}
                      columns={columns}
                      pageSize={pageSize}
                      checkboxSelection={tabValue === 0}
                      selectionModel={selectedFiles}
                      onSelectionModelChange={(newSelection) => {
                        setSelectedFiles(newSelection);
                      }}
                      onRowClick={handleRowClick}
                      getRowClassName={(params) => (params.id === highlightedRowId ? "highlighted-row" : "")}
                      onCellKeyDown={handleCellKeyDown}
                      sx={{
                        height: "100%",
                        width: "100%",
                        borderRadius: 1,
                        backgroundColor: "background.paper",
                        boxShadow: 2,
                        "& .MuiDataGrid-cell:hover": {
                          color: "primary.main",
                        },
                        "& .highlighted-row": {
                          backgroundColor: "rgba(25, 118, 210, 0.08)",
                          "&:hover": {
                            backgroundColor: "rgba(25, 118, 210, 0.15)",
                          },
                        },
                      }}
                    />
                  </div>
                  {/* Reserve fixed space for buttons */}
                  <Box display="flex" gap={2} mt={2} mb={2} style={{ height: "34px" }}>
                    {tabValue === 0 && (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleDownloadClick}
                          disabled={selectedFiles.length === 0}
                        >
                          Download Selected Files
                        </Button>
                        <Button variant="contained" color="warning" onClick={handleUploadClick} disabled={selectedFiles.length === 0}>
                          Upload to OCR System
                        </Button>
                        <Button
                          type="button"
                          onClick={() => setIsConfirmDialogOpen(true)}
                          variant="contained"
                          color="success"
                          disabled={setProcessedByDwsInProgress || selectedFiles.length === 0}
                        >
                          {setProcessedByDwsInProgress ? (
                            <Box display="flex" alignItems="center">
                              Processing...
                              <CircularProgress size={20} sx={{ ml: 1 }} />
                            </Box>
                          ) : (
                            "Set Processed By DWS"
                          )}
                        </Button>
                      </>
                    )}
                  </Box>
                </Card>
              </div>
            </div>
          </Panel>

          {/* Resize Handle */}
          <PanelResizeHandle
            style={{
              width: rightPaneSize === 0 ? "0px" : "5px",
              cursor: rightPaneSize === 0 ? "default" : "col-resize",
              backgroundColor: rightPaneSize === 0 ? "transparent" : "#ddd",
              borderLeft: rightPaneSize === 0 ? "none" : "1px solid #aaa",
            }}
          />

          {/* Right Panel */}
          <Panel minSize={0} style={{ overflow: "hidden", display: rightPaneSize === 0 ? "none" : "block" }}>
            {rightPaneSize !== 0 && (
              <div style={{ width: "100%", height: "100%" }}>
                <Card style={{ height: "100%", position: "relative" }}>
                  <CardContent style={{ height: "100%", paddingBottom: 16 }}>
                    <IconButton
                      onClick={() => {
                        setRightPaneSize(0); // Close the right pane
                        setHighlightedRowId(null); // Optionally clear the highlighted row
                        setViewCustomerInvoiceDialog({
                          ...viewCustomerInvoiceDialog,
                          isOpen: false,
                        });
                      }}
                      style={{ position: "absolute", top: 8, right: 8 }}
                    >
                      <CloseIcon />
                    </IconButton>
                    <ViewCustomerInvoiceUploadDialog
                      viewCustomerInvoiceDialog={viewCustomerInvoiceDialog}
                      setViewCustomerInvoiceDialog={setViewCustomerInvoiceDialog}
                      countryArr={countryData}
                      supplierArr={supplierData}
                      currencyArr={currencyData}
                      productTypeArr={productData}
                      invoiceStateArr={invoiceStateData}
                      refreshGrid={fetchCustomerFiles}
                    />
                  </CardContent>
                </Card>
              </div>
            )}
          </Panel>
        </PanelGroup>
      </div>
      <>
        {/* Confirmation Dialog for Upload */}
        <Dialog
          open={isUploadDialogOpen}
          onClose={handleCancelUpload}
          aria-labelledby="upload-confirmation-dialog-title"
          aria-describedby="upload-confirmation-dialog-description"
        >
          <DialogTitle id="upload-confirmation-dialog-title">Confirm Upload</DialogTitle>
          <DialogContent>
            <DialogContentText id="upload-confirmation-dialog-description">
              Are you sure you want to upload the invoices to the OCR system? Uploaded files are removed from this page and are moved
              to the history tab of the Add Automated Invoices menu.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancelUpload} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirmUpload} color="warning" variant="contained" autoFocus disabled={uploading}>
              {uploading ? <LinearProgress style={{ width: "100%" }} /> : "Upload"}
            </Button>
          </DialogActions>
        </Dialog>

        {/* Optionally, display upload results */}
        <Dialog open={uploadResults.length > 0} onClose={() => setUploadResults([])} maxWidth="md" fullWidth>
          <DialogTitle>Upload Results</DialogTitle>
          <DialogContent>
            <table style={{ width: "100%", borderCollapse: "collapse" }}>
              <thead>
                <tr>
                  <th style={{ borderBottom: "1px solid #ccc", padding: "8px" }}>File Name</th>
                  <th style={{ borderBottom: "1px solid #ccc", padding: "8px" }}>Status</th>
                </tr>
              </thead>
              <tbody>
                {uploadResults.map((result, index) => (
                  <tr key={index}>
                    <td style={{ borderBottom: "1px solid #eee", padding: "8px" }}>{result.file}</td>
                    <td style={{ borderBottom: "1px solid #eee", padding: "8px" }}>
                      {result.status.includes("successfully") ? <CheckCircleIcon color="success" /> : <ErrorIcon color="error" />}
                      {result.status}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setUploadResults([])} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </>
      <Dialog open={isConfirmDialogOpen} onClose={() => setIsConfirmDialogOpen(false)}>
        <DialogTitle>Confirm</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to move these invoices to the historic list?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsConfirmDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleProcessedByDWSClick();
              setIsConfirmDialogOpen(false);
            }}
            color="warning"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default CustomerDataGrid;
