import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";

export const GlobalContext = createContext();

const hasRequiredRole = (roles, requiredRole) => {
  return roles.includes(requiredRole);
};

const GlobalProvider = ({ children }) => {
  const [userId, setUserId] = useState(sessionStorage.getItem("userId"));
  const [displayName, setDisplayName] = useState(sessionStorage.getItem("displayName"));
  const [email, setEmail] = useState(sessionStorage.getItem("email"));
  const [roles, setRoles] = useState(() => {
    const storedRoles = sessionStorage.getItem("roles");
    return storedRoles ? JSON.parse(storedRoles) : [];
  });
  const [userName, setUserName] = useState(sessionStorage.getItem("username"));
  const [isAuthorized, setIsAuthorized] = useState(hasRequiredRole(roles, "DWSUser"));

  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const location = useLocation();

  useEffect(() => {
    const getNotifications = async () => {
      try {
        const response = await axios.get("/api/v1/notifications");
        setNotifications(response.data);
        setUnreadCount(response.data.filter((n) => !n.isRead).length);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    getNotifications();
  }, [location]);

  const fetchUserData = async () => {
    try {
      const response = await axios.get("/api/v1/GetAppUserInformation/");
      const { id, firstname, lastname, userName, email, roles } = response.data;

      setUserId(id);
      setUserName(userName);
      setDisplayName(`${firstname} ${lastname}`);
      setEmail(email);
      setRoles(roles);

      sessionStorage.setItem("userId", id);
      sessionStorage.setItem("email", email);
      sessionStorage.setItem("roles", JSON.stringify(roles));
      sessionStorage.setItem("displayName", `${firstname} ${lastname}`);
      sessionStorage.setItem("userName", userName);

      setIsAuthorized(hasRequiredRole(roles, "DWSUser"));
    } catch (error) {
      console.error("Error fetching user info:", error);
    }
  };

  useEffect(() => {
    const storedUserId = sessionStorage.getItem("userId");
    if (!storedUserId) {
      fetchUserData();
    } else {
      setUserId(storedUserId);
      setDisplayName(sessionStorage.getItem("displayName"));
      setEmail(sessionStorage.getItem("email"));
      setRoles(JSON.parse(sessionStorage.getItem("roles") || "[]"));
      setUserName(sessionStorage.getItem("username"));
      setIsAuthorized(hasRequiredRole(JSON.parse(sessionStorage.getItem("roles") || "[]"), "DWSUser"));
    }
  }, [userId]);

  const globalData = {
    userId,
    email,
    roles,
    displayName,
    userName,
    isAuthorized: hasRequiredRole(roles, "DWSUser"),
    notifications,
    unreadCount,
  };

  return (
    <GlobalContext.Provider
      value={{
        globalData,
        isAuthorized,
        fetchUserData,
        notifications,
        setNotifications,
        unreadCount,
        setUnreadCount,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalProvider;
