import React, { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Button as MuiButton,
  Divider,
  TextField as MuiTextField,
  Alert as MuiAlert,
  Box,
  IconButton,
} from "@mui/material";
import axios from "axios";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import apiUtils from "../utils/apiUtils";
import Select from "./SelectBox";
import AddIcon from "@mui/icons-material/Add";

const Card = styled(MuiCard)(spacing);
const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Button = styled(MuiButton)(spacing);

const validationSchema = Yup.object().shape({
  username: Yup.string().required("Required"),
  password: Yup.string().required("Required"),
  supplierPortalId: Yup.string().required("Required"),
  // Email is optional.
});

export default function AddSupplierPortalLogin(props) {
  const { onDataRefresh, customerId } = props;
  const supplierPortalData = apiUtils.useApiData("/api/v1/supplierPortalInfo");
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");

  const clearAlertAfterTimeout = () => {
    setTimeout(() => {
      setAlertMessage("");
      setAlertSeverity("");
    }, 3000);
  };

  const initialValues = {
    username: "",
    password: "",
    supplierPortalId: "",
    email: "",
  };

  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    try {
      const newPortalLogin = {
        username: values.username,
        password: values.password,
        supplierPortalId: values.supplierPortalId,
        email: values.email,
        entityId: customerId, // assuming the customerId is used as entityId
        customerLoginTypeId: 1, // Possible Types: Unknown = 0, SupplierPortal = 1, EUVATPortal = 2, ExcisePortal = 3, OtherLogins = 4
      };

      await axios.post(`/api/v1/customerLogins`, newPortalLogin);
      setAlertMessage("Your data has been submitted successfully!");
      setAlertSeverity("success");
      onDataRefresh();
      resetForm();
      clearAlertAfterTimeout();
      // Close dialog after 3 seconds
      setTimeout(() => {
        setOpen(false);
      }, 3000);
      setSubmitting(false);
    } catch (error) {
      setAlertMessage(error.response?.data || error.message);
      setAlertSeverity("error");
      clearAlertAfterTimeout();
      setSubmitting(false);
    }
  };

  return (
    <React.Fragment>
      {/* <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
        Add Supplier Portal Login
      </Button> */}
      <IconButton style={{ margin: 0, padding: 0 }} color="primary" onClick={() => setOpen(true)}>
        <AddIcon />
      </IconButton>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="sm">
        <DialogTitle id="add-dialog-title">Add Supplier Portal Login</DialogTitle>
        <Divider />
        {alertMessage && (
          <Alert severity={alertSeverity} my={3}>
            {alertMessage}
          </Alert>
        )}
        <DialogContent>
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
              <form onSubmit={handleSubmit}>
                <Card mb={6}>
                  <CardContent>
                    <Grid container spacing={6}>
                      <Grid item md={12} xs={12}>
                        <Select
                          name="supplierPortalId"
                          label="Supplier Portal"
                          options={JSON.parse(
                            JSON.stringify(JSON.parse(JSON.stringify(supplierPortalData).split('"supplierPortalId":').join('"key":')))
                              .split('"name":')
                              .join('"value":')
                          )}
                          onChange={handleChange}
                          formControlCustomWidth="100%"
                          my={0}
                        />
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <TextField
                          name="username"
                          label="Username"
                          value={values.username}
                          error={Boolean(touched.username && errors.username)}
                          fullWidth
                          helperText={touched.username && errors.username}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <TextField
                          name="password"
                          label="Password"
                          value={values.password}
                          error={Boolean(touched.password && errors.password)}
                          fullWidth
                          helperText={touched.password && errors.password}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <TextField
                          name="email"
                          label="Email Address"
                          value={values.email}
                          error={Boolean(touched.email && errors.email)}
                          fullWidth
                          helperText={touched.email && errors.email}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    {isSubmitting ? (
                      <Box display="flex" justifyContent="center" my={2}>
                        <CircularProgress />
                      </Box>
                    ) : (
                      <Box mt={2} display="flex" justifyContent="space-between">
                        <Button type="submit" variant="contained" color="primary">
                          Submit
                        </Button>
                        <Button type="button" variant="contained" onClick={() => setOpen(false)}>
                          Close
                        </Button>
                      </Box>
                    )}
                  </CardContent>
                </Card>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
