import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { Formik } from "formik";
import axios from "axios";
import * as moment from "moment";
// import { DesktopDatePicker as MuiDesktopDatePicker } from "@mui/x-date-pickers";
// import TextFieldProps from "@mui/material";
// import { LocalizationProvider } from "@mui/x-date-pickers";
// import Select from "../../components/formsui/select"
// import DatePickerField from "../../components/DatePicker";
// import { format } from "date-fns";
import {
  Alert as MuiAlert,
  Box,
  // Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  // Divider as MuiDivider,
  Grid,
  TextField as MuiTextField,
  Typography,
  Collapse,
  IconButton,
  // FormControl as MuiFormControl,
  // Paper as MuiPaper,
  // InputLabel,
  // MenuItem,
} from "@mui/material";
import { spacing } from "@mui/system";
import SelectWrapper from "../../components/SelectBox";
import CloseIcon from "@mui/icons-material/Close";
// import { is } from "date-fns/locale";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import dayjs, { Dayjs } from "dayjs";

// const DesktopDatePicker = styled(MuiDesktopDatePicker);

const Card = styled(MuiCard)(spacing);

// const Divider = styled(MuiDivider)(spacing);

// const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

// const TextField = styled(TextFieldSpacing)`
//   width: 200px;
// `;

const Button = styled(MuiButton)(spacing);

// const Paper = styled(MuiPaper)(spacing);

// const FormControlSpacing = styled(MuiFormControl)(spacing);

// const FormControl = styled(FormControlSpacing)`
//   min-width: 148px;
// `;

const BasicForm = (props) => {
  const customerId = props.customerId;

  const timeOut = (time) => new Promise((res) => setTimeout(res, time));
  // console.table(props);
  // console.log("Customer ID: " + customerId);

  const [entityData, setEntityData] = useState([]);

  useEffect(() => {
    axios.get("/api/v1/entities/" + customerId).then((response) => {
      console.log("Response from /entities/: ", response.data);
      setEntityData(response.data);
    });
  }, [customerId]);

  useEffect(() => {
    if (entityData && entityData.countryId !== undefined) {
      setIsPoland(entityData.countryId === 17);
    }
  }, [entityData]);

  const [countryData, setCountryData] = useState([]);
  const [isPoland, setIsPoland] = useState(false);

  useEffect(() => {
    async function fetchData() {
      axios.get("/api/v1/countries").then((response) => setCountryData(response.data));
    }
    fetchData();
  }, []);

  const [relationTypeData, setRelationTypeData] = useState([]);

  useEffect(() => {
    axios.get("/api/v1/relationTypes").then((response) => setRelationTypeData(response.data));
  }, []);

  const formatDate = (date = new Date()) => {
    let year, month, day;

    year = date.getFullYear();
    month = date.getMonth() + 1;
    day = date.getDate();

    month = month.toString().padStart(2, 0);
    day = day.toString().padStart(2, 0);

    return `${year}-${month}-${day}`;
  };

  var date2 = new Date(entityData.startupDate);

  let startupDate = formatDate(date2);

  const initialValues = {
    entityNumber: entityData.entityNumber,
    relationTypeId: entityData.typeRelation,
    companyName: entityData.name,
    companyAddress: entityData.address,
    companyPostalCode: entityData.postalCode,
    companyCity: entityData.city,
    companyProvince: entityData.province,
    companyCountry: entityData.countryId,
    email: entityData.generalEmailAddress,
    phonenumber: entityData.generalPhoneNumber,
    coc: entityData.coC,
    vat: entityData.vat,
    taxOffice: entityData.taxOffice,
    website: entityData.website,
    startupDate: startupDate,
    houseNumber: entityData.houseNumber || "",
    county: entityData.county || "",
    municipality: entityData.municipality || "",
    postOffice: entityData.postOffice || "",
  };

  const validationSchema = Yup.object().shape({
    entityNumber: Yup.string().required("Required"),
    // // relationType: Yup.string().required("Required"),
    companyName: Yup.string().required("Required"),
    companyAddress: Yup.string().required("Required"),
    companyPostalCode: Yup.string().required("Required"),
    companyCity: Yup.string().required("Required"),
    companyProvince: Yup.string().required("Required"),
    // // companyCountry: Yup.string().required("Required"),
    coc: Yup.string().required("Required"),
    vat: Yup.string().required("Required"),
    taxOffice: Yup.string().required("Required"),
    website: Yup.string().required("Required"),
    email: Yup.string().email().required("Required"),
    phonenumber: Yup.number().required("Required"),
  });

  // var errorMessage = "";
  // var submitInError = false;

  const [openAlert, setOpenAlert] = React.useState(false);
  const [alertSeverity, setAlertSeverity] = useState("info");
  const [alertMessage, setAlertMessage] = useState("");

  const handleSubmit = async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
    try {
      await timeOut(500);

      const test = {
        entityNumber: values.entityNumber,
        typeRelation: values.relationTypeId,
        name: values.companyName,
        address: values.companyAddress,
        postalCode: values.companyPostalCode,
        city: values.companyCity,
        province: values.companyProvince,
        countryId: values.companyCountry,
        coC: values.coc,
        vat: values.vat,
        taxOffice: values.taxOffice,
        website: values.website,
        generalEmailAddress: values.email,
        generalPhoneNumber: values.phonenumber,
        startupDate: date,
        modifiedDate: moment().format("YYYY-MM-DDThh:mm:ss.SSS") + "Z",
        isDisabled: true,
        entityStatusId: 0,
        houseNumber: isPoland ? values.houseNumber : "",
        county: isPoland ? values.county : "",
        municipality: isPoland ? values.municipality : "",
        postOffice: isPoland ? values.postOffice : "",
      };

      axios
        .put("/api/v1/entities/" + props.customerId, test)
        .then((response) => {
          // errorMessage = "Your data has been submitted successfully!";
          // submitInError = false;
          setOpenAlert(true);
          setAlertSeverity("success");
          setAlertMessage("Customer has been updated successfully");
          setTimeout(() => {
            setOpenAlert(false);
          }, 5000);
        })
        .catch(function (error) {
          if (error.response) {
            // submitInError = true;
            setOpenAlert(true);
            setAlertSeverity("success");
            setAlertMessage("Customer update failed!");
            setTimeout(() => {
              setOpenAlert(false);
            }, 10000);
          }
        });

      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  const [date, setDate] = useState();

  // handles when user changes input in date inputfield
  const handleChangeDate = (e) => {
    setDate(e.target.value);
  };

  return entityData.entityNumber === undefined ? null : (
    <React.Fragment>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        //onChange={handleChange}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, status }) => (
          <Card mb={6}>
            <CardContent>
              {/* <Typography variant="h6" gutterBottom>
              New Entity Information
            </Typography> */}

              <Box sx={{ width: "100%" }}>
                <Collapse in={openAlert}>
                  <Alert
                    severity={alertSeverity}
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setOpenAlert(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                    sx={{ mb: 2 }}
                  >
                    {alertMessage}
                  </Alert>
                </Collapse>
              </Box>

              <form onSubmit={handleSubmit}>
                <Typography variant="body2" gutterBottom mb={4}>
                  Entity Information
                </Typography>
                <Grid container spacing={6}>
                  <Grid item md={4} xs={12}>
                    <TextField
                      name="entityNumber"
                      label="Customer Number"
                      value={values.entityNumber}
                      error={Boolean(touched.entityNumber && errors.entityNumber)}
                      fullWidth
                      helperText={touched.entityNumber && errors.entityNumber}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="entityNumber"
                      variant="outlined"
                      my={2}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      name="companyName"
                      label="Company Name"
                      value={values.companyName}
                      error={Boolean(touched.companyName && errors.companyName)}
                      fullWidth
                      helperText={touched.companyName && errors.companyName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="companyName"
                      variant="outlined"
                      my={2}
                    />
                  </Grid>
                  <Grid item md={2} xs={12}>
                    <SelectWrapper
                      name="relationTypeId"
                      label="Relation Type"
                      options={JSON.parse(
                        JSON.stringify(JSON.parse(JSON.stringify(relationTypeData).split('"relationTypeId":').join('"key":')))
                          .split('"shortDescription":')
                          .join('"value":')
                      )}
                    />
                  </Grid>
                </Grid>
                {isPoland ? (
                  <Grid container spacing={6}>
                    <Grid item md={4} xs={12}>
                      <TextField
                        name="companyAddress"
                        label="Street"
                        value={values.companyAddress}
                        error={Boolean(touched.companyAddress && errors.companyAddress)}
                        fullWidth
                        helperText={touched.companyAddress && errors.companyAddress}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                      />
                    </Grid>
                    <Grid item md={2} xs={12}>
                      <TextField
                        name="houseNumber"
                        label="House Number"
                        value={values.houseNumber}
                        error={Boolean(touched.houseNumber && errors.houseNumber)}
                        fullWidth
                        helperText={touched.houseNumber && errors.houseNumber}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                      />
                    </Grid>
                    <Grid item md={2} xs={12}>
                      <TextField
                        name="companyPostalCode"
                        label="Postal Code"
                        value={values.companyPostalCode}
                        error={Boolean(touched.companyPostalCode && errors.companyPostalCode)}
                        fullWidth
                        helperText={touched.companyPostalCode && errors.companyPostalCode}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <TextField
                        name="companyCity"
                        label="City"
                        value={values.companyCity}
                        error={Boolean(touched.companyCity && errors.companyCity)}
                        fullWidth
                        helperText={touched.companyCity && errors.companyCity}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container spacing={6}>
                    <Grid item md={8} xs={12}>
                      <TextField
                        name="companyAddress"
                        label="Address"
                        value={values.companyAddress}
                        error={Boolean(touched.companyAddress && errors.companyAddress)}
                        fullWidth
                        helperText={touched.companyAddress && errors.companyAddress}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <TextField
                        name="companyPostalCode"
                        label="Postal Code"
                        value={values.companyPostalCode}
                        error={Boolean(touched.companyPostalCode && errors.companyPostalCode)}
                        fullWidth
                        helperText={touched.companyPostalCode && errors.companyPostalCode}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                      />
                    </Grid>
                  </Grid>
                )}
                {isPoland ? (
                  <Grid container spacing={6}>
                    <Grid item md={3} xs={12}>
                      <TextField
                        name="companyProvince"
                        label="Province"
                        value={values.companyProvince}
                        error={Boolean(touched.companyProvince && errors.companyProvince)}
                        fullWidth
                        helperText={touched.companyProvince && errors.companyProvince}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        name="county"
                        label="County"
                        value={values.county}
                        error={Boolean(touched.county && errors.county)}
                        fullWidth
                        helperText={touched.county && errors.county}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <TextField
                        name="municipality"
                        label="Municipality"
                        value={values.municipality}
                        error={Boolean(touched.municipality && errors.municipality)}
                        fullWidth
                        helperText={touched.municipality && errors.municipality}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                      />
                    </Grid>
                    <Grid item md={2} xs={12}>
                      <SelectWrapper
                        name="companyCountry"
                        label="Country"
                        options={JSON.parse(
                          JSON.stringify(JSON.parse(JSON.stringify(countryData).split('"countryId":').join('"key":')))
                            .split('"countryCode":')
                            .join('"value":')
                        )}
                        onChange={(e) => {
                          handleChange(e);
                          setIsPoland(e.target.value === 17);
                        }}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container spacing={6}>
                    <Grid item md={5} xs={12}>
                      <TextField
                        name="companyCity"
                        label="City"
                        value={values.companyCity}
                        error={Boolean(touched.companyCity && errors.companyCity)}
                        fullWidth
                        helperText={touched.companyCity && errors.companyCity}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                      />
                    </Grid>
                    <Grid item md={5} xs={12}>
                      <TextField
                        name="companyProvince"
                        label="Province"
                        value={values.companyProvince}
                        error={Boolean(touched.companyProvince && errors.companyProvince)}
                        fullWidth
                        helperText={touched.companyProvince && errors.companyProvince}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                      />
                    </Grid>
                    <Grid item md={2} xs={12}>
                      <SelectWrapper
                        name="companyCountry"
                        label="Country"
                        options={JSON.parse(
                          JSON.stringify(JSON.parse(JSON.stringify(countryData).split('"countryId":').join('"key":')))
                            .split('"countryCode":')
                            .join('"value":')
                        )}
                        onChange={(e) => {
                          handleChange(e);
                          setIsPoland(e.target.value === 17);
                        }}
                      />
                    </Grid>
                  </Grid>
                )}
                <Grid container spacing={6} variant="outlined">
                  <Grid item md={6} xs={12}>
                    <TextField
                      name="email"
                      label="General Email Address"
                      value={values.email}
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      variant="outlined"
                      my={2}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <TextField
                      name="phonenumber"
                      label="General Phone Number"
                      value={values.phonenumber}
                      error={Boolean(touched.phonenumber && errors.phonenumber)}
                      fullWidth
                      helperText={touched.phonenumber && errors.phonenumber}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="phone"
                      variant="outlined"
                      my={2}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={6} variant="outlined">
                  <Grid item md={6} xs={12}>
                    <TextField
                      name="coc"
                      label="Chamber of Commerce"
                      value={values.coc}
                      error={Boolean(touched.coc && errors.coc)}
                      fullWidth
                      helperText={touched.coc && errors.coc}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="coc"
                      variant="outlined"
                      my={2}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      name="vat"
                      label="VAT"
                      value={values.vat}
                      error={Boolean(touched.vat && errors.vat)}
                      fullWidth
                      helperText={touched.vat && errors.vat}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="vat"
                      variant="outlined"
                      my={2}
                    />
                  </Grid>
                </Grid>
                {isPoland ? (
                  <Grid container spacing={6} variant="outlined">
                    <Grid item md={6} xs={12}>
                      <TextField
                        name="taxOffice"
                        label="Tax Office"
                        value={values.taxOffice}
                        error={Boolean(touched.taxOffice && errors.taxOffice)}
                        fullWidth
                        helperText={touched.taxOffice && errors.taxOffice}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="taxOffice"
                        variant="outlined"
                        my={2}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        name="postOffice"
                        label="Post Office"
                        value={values.postOffice}
                        error={Boolean(touched.postOffice && errors.postOffice)}
                        fullWidth
                        helperText={touched.postOffice && errors.postOffice}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="postOffice"
                        variant="outlined"
                        my={2}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container spacing={6} variant="outlined">
                    <Grid item md={12} xs={12}>
                      <TextField
                        name="taxOffice"
                        label="Tax Office"
                        value={values.taxOffice}
                        error={Boolean(touched.taxOffice && errors.taxOffice)}
                        fullWidth
                        helperText={touched.taxOffice && errors.taxOffice}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="taxOffice"
                        variant="outlined"
                        my={2}
                      />
                    </Grid>
                  </Grid>
                )}
                <Grid container spacing={6} variant="outlined">
                  <Grid item md={6} xs={12}>
                    <TextField
                      name="website"
                      label="Website"
                      value={values.website}
                      error={Boolean(touched.website && errors.website)}
                      fullWidth
                      helperText={touched.website && errors.website}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="website"
                      variant="outlined"
                      my={2}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      // id="startupDate"
                      name="startupDate"
                      label="Start Date"
                      type="date"
                      defaultValue={startupDate}
                      fullWidth
                      value={date}
                      onChange={handleChangeDate}
                      // sx={{ width: 220 }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      my={2}
                    />
                  </Grid>
                </Grid>
                {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Departure Date"
                      id="departureDate"
                      name="departureDate"
                      value={formik.values.departureDate}
                      onChange={(value) => {
                        formik.setFieldValue(
                          "departureDate",
                          Date.parse(value)
                        );
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider> */}
                {/* <DatePickerField name="date" /> */}
                {/* <DesktopDatePicker
                    label="endDate"
                    inputFormat="MMDDYYYY"
                    value={values.endDate}
                    onChange={setValue}
                    renderInput={(params: TextFieldProps) => {
                      return <TextField {...params} />;
                    }}
                    views={["day", "month"]}
                  /> */}
                {/* <DatePicker name="endDate" label="endDate" /> */}
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  variant="contained"
                  color="primary"
                  mt={3}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span style={{ marginRight: "10px" }}>Save changes</span>
                  {isSubmitting && <CircularProgress size={20} />}
                </Button>
              </form>
            </CardContent>
          </Card>
        )}
      </Formik>
    </React.Fragment>
  );
};

// function Blank() {
//   return (
//     <React.Fragment>
//       <BasicForm />
//     </React.Fragment>
//   );
// }

export default BasicForm;
