import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  Card,
  CardContent,
  CircularProgress,
  Button,
  TextField,
  Divider,
  Alert,
  Box,
} from "@mui/material";
import axios from "axios";
import styled from "@emotion/styled";

const StyledCard = styled(Card)();
const StyledCardContent = styled(CardContent)();
const StyledTextField = styled(TextField)();
const StyledButton = styled(Button)();
const StyledAlert = styled(Alert)();

const initialValues = {
  name: "",
  url: "",
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  url: Yup.string().url("Must be a valid URL").nullable(),
});

const AddSupplierPortalInfo = (props) => {
  const { refreshGrid } = props;
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [submitInError, setSubmitInError] = useState(false);

  const clearMessageAfterTimeout = () => {
    setTimeout(() => {
      setErrorMessage("");
    }, 3000);
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const newInfo = {
        name: values.name,
        url: values.url,
      };

      await axios
        .post("/api/v1/supplierPortalInfo", newInfo)
        .then((response) => {
          setErrorMessage("Your data has been submitted successfully!");
          setSubmitInError(false);
          refreshGrid();
          resetForm();
          clearMessageAfterTimeout();
        })
        .catch((error) => {
          // Controleer op duplicate key error (bijv. SQL errorcode of berichtinhoud)
          let errMsg = "Error submitting new Supplier Portal Info.";
          if (error.response && error.response.data && error.response.data.toLowerCase().includes("duplicate")) {
            errMsg = "Name already exists.";
          }
          setSubmitInError(true);
          setErrorMessage(errMsg);
          clearMessageAfterTimeout();
        });
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
    }
  };

  return (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
          Add Supplier Portal Info
        </Button>
        <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="add-dialog-title">
          <DialogTitle id="add-dialog-title">Add Supplier Portal Info</DialogTitle>
          <Divider />
          <DialogContent>
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
              {({ errors, touched, handleChange, handleBlur, handleSubmit, values, isSubmitting }) => (
                <form onSubmit={handleSubmit}>
                  <StyledCard>
                    <StyledCardContent>
                      {errorMessage &&
                        (submitInError ? (
                          <StyledAlert severity="error" sx={{ my: 2 }}>
                            {errorMessage}
                          </StyledAlert>
                        ) : (
                          <StyledAlert severity="success" sx={{ my: 2 }}>
                            {errorMessage}
                          </StyledAlert>
                        ))}
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <StyledTextField
                            name="name"
                            label="Name"
                            fullWidth
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.name && Boolean(errors.name)}
                            helperText={touched.name && errors.name}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <StyledTextField
                            name="url"
                            label="URL"
                            fullWidth
                            value={values.url}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.url && Boolean(errors.url)}
                            helperText={touched.url && errors.url}
                          />
                        </Grid>
                      </Grid>
                      {isSubmitting ? (
                        <Box display="flex" justifyContent="center" my={2}>
                          <CircularProgress />
                        </Box>
                      ) : (
                        <Box mt={2} display="flex" justifyContent="space-between">
                          <StyledButton type="submit" variant="contained" color="primary">
                            Submit
                          </StyledButton>
                          <StyledButton type="button" variant="contained" onClick={() => setOpen(false)}>
                            Close
                          </StyledButton>
                        </Box>
                      )}
                    </StyledCardContent>
                  </StyledCard>
                </form>
              )}
            </Formik>
          </DialogContent>
        </Dialog>
      </CardContent>
    </Card>
  );
};

export default AddSupplierPortalInfo;
