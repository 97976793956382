import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  Grid,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Card,
  CardContent,
  CircularProgress,
  Button,
  Divider,
  TextField,
  Alert,
} from "@mui/material";
import axios from "axios";
import styled from "@emotion/styled";

const StyledCard = styled(Card)();
const StyledCardContent = styled(CardContent)();
const StyledTextField = styled(TextField)();
const StyledButton = styled(Button)();
const StyledAlert = styled(Alert)();

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  url: Yup.string().url("Must be a valid URL").nullable(),
});

const ViewSupplierPortalInfoDialog = (props) => {
  const { viewDialog, setViewDialog, refreshGrid } = props;

  const initialValues = {
    supplierPortalId: viewDialog?.params?.supplierPortalId,
    name: viewDialog?.params?.name,
    url: viewDialog?.params?.url || "",
  };

  const [errorMessage, setErrorMessage] = useState("");
  const [submitInError, setSubmitInError] = useState(false);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const updatedInfo = {
        name: values.name,
        url: values.url,
      };
      await axios
        .put("/api/v1/supplierPortalInfo/" + values.supplierPortalId, updatedInfo)
        .then((response) => {
          setErrorMessage("Your data has been submitted successfully!");
          setSubmitInError(false);
          refreshGrid();
        })
        .catch((error) => {
          setSubmitInError(true);
          setErrorMessage("Error updating Supplier Portal Info.");
        });
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
    }
  };

  return (
    <Dialog
      open={viewDialog.isOpen}
      onClose={() => setViewDialog({ ...viewDialog, isOpen: false })}
      aria-labelledby="view-dialog-title"
    >
      <DialogTitle id="view-dialog-title">{viewDialog.title}</DialogTitle>
      <Divider />
      <DialogContent>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {({ errors, touched, handleChange, handleBlur, handleSubmit, values, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <StyledCard>
                <StyledCardContent>
                  {submitInError ? (
                    <StyledAlert severity="error" sx={{ my: 2 }}>
                      {errorMessage}
                    </StyledAlert>
                  ) : errorMessage ? (
                    <StyledAlert severity="success" sx={{ my: 2 }}>
                      {errorMessage}
                    </StyledAlert>
                  ) : null}
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <StyledTextField
                        name="name"
                        label="Name"
                        fullWidth
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.name && Boolean(errors.name)}
                        helperText={touched.name && errors.name}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <StyledTextField
                        name="url"
                        label="URL"
                        fullWidth
                        value={values.url}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.url && Boolean(errors.url)}
                        helperText={touched.url && errors.url}
                      />
                    </Grid>
                  </Grid>
                  {isSubmitting ? (
                    <Box display="flex" justifyContent="center" my={2}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <Box mt={2} display="flex" justifyContent="space-between">
                      <StyledButton type="submit" variant="contained" color="primary">
                        Update
                      </StyledButton>
                      <StyledButton type="button" variant="contained" onClick={() => setViewDialog({ ...viewDialog, isOpen: false })}>
                        Close
                      </StyledButton>
                    </Box>
                  )}
                </StyledCardContent>
              </StyledCard>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default ViewSupplierPortalInfoDialog;
