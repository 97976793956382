import React, { useState, useEffect } from "react";
import { Dialog, DialogContent, DialogTitle, Divider, Button, Typography, Box, Paper, IconButton } from "@mui/material";
import { DataGrid, GridToolbar, GridFooter } from "@mui/x-data-grid";
import axios from "axios";
import moment from "moment";
import apiUtils from "../utils/apiUtils";
import VisibilityIcon from "@mui/icons-material/Visibility";

const ViewHistoricClaimDialog = ({ viewClaimDialog, setViewHistoricClaimDialog }) => {
  const { params } = viewClaimDialog;
  const [historicData, setHistoricData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [pageSize, setPageSize] = useState(15);

  // Haal lookup data op (zoals in ViewClaimDialog)
  const countryData = apiUtils.useApiData("/api/v1/countries");
  const claimStateData = apiUtils.useApiData("/api/v1/claimStates");
  const bankAccounts = apiUtils.useApiData("/api/v1/bankAccounts");
  const supplierData = apiUtils.useApiData("/api/v1/suppliers");

  // Haal de volledige historic claim view op (inclusief facturen)
  useEffect(() => {
    if (params?.historicClaimId) {
      setLoading(true);
      axios
        .get(`/api/v1/claims/historicClaimView/${params.historicClaimId}`)
        .then((response) => {
          setHistoricData(response.data);
        })
        .catch((error) => {
          console.error("Error fetching historic claim view:", error);
        })
        .finally(() => setLoading(false));
    }
  }, [params]);

  const invoiceGridData = historicData?.historicInvoices || [];

  const columns = [
    { field: "invoiceNumber", headerName: "Invoice Number", flex: 0.5 },
    {
      field: "invoiceDate",
      headerName: "Invoice Date",
      flex: 0.4,
      valueFormatter: (p) => moment(p.value).format("DD-MM-YYYY"),
    },
    {
      field: "supplierId",
      headerName: "Supplier",
      flex: 0.5,
      valueGetter: (params) => supplierData.find((supplier) => supplier.supplierId === params.row.supplierId)?.supplierName,
    },
    {
      field: "invoiceVATAmountLocalCurrency",
      headerName: "Local VAT",
      flex: 0.5,
      renderCell: (p) => (
        <div style={{ textAlign: "right", paddingRight: "5px" }}>
          {p.value.toLocaleString("nl-NL", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
        </div>
      ),
      align: "right",
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.3,
      renderCell: (p) => (
        <IconButton
          onClick={(e) => {
            // setViewCustomerInvoiceDialog({
            //   isOpen: true,
            //   downloadFile: true,
            //   title: "Manage Invoice",
            //   params: params.row,
            //   custInfo: [{ customerId: customerId, customerNumber: customerNumber }],
            //   productInfo: [
            //     {
            //       productCode: params.row.claimProductCode,
            //       productSubCode: params.row.claimProductSubCode,
            //     },
            //   ],
            //   subTitle: "Are you sure you want to manage " + params.row.contactName + "?",
            //   handleFile: () => {
            //     handleFile(updatedData);
            //   },
            // });
          }}
        >
          <VisibilityIcon />
        </IconButton>
      ),
    },
  ];

  const handleClose = () => {
    setViewHistoricClaimDialog({ ...viewClaimDialog, isOpen: false });
  };

  // Hulpfunctie: toont label en waarde in één rij met minimaal 200px breedte voor het label.
  const renderField = (label, value) => (
    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
      <Box sx={{ minWidth: 200 }}>
        <Typography variant="subtitle2" color="textSecondary">
          {label}
        </Typography>
      </Box>
      <Typography variant="body1">{value}</Typography>
    </Box>
  );

  return (
    <Dialog open={viewClaimDialog.isOpen} onClose={handleClose} fullWidth maxWidth="lg">
      <DialogTitle sx={{ fontWeight: "bold" }}>{viewClaimDialog.title}</DialogTitle>
      <Divider />
      <DialogContent sx={{ py: 3 }}>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: 200 }}>
            <Typography>Loading historic claim...</Typography>
          </Box>
        ) : historicData ? (
          <>
            <Paper sx={{ p: 2, mb: 3 }}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                {renderField("Claim Code:", historicData.claimCode)}
                {renderField("Reference Number:", historicData.taxOfficeRefNo)}
                {renderField("Year:", historicData.year)}
                {renderField(
                  "Period:",
                  `${String(historicData.startMonth).padStart(2, "0")} / ${String(historicData.endMonth).padStart(2, "0")}`
                )}
                {renderField(
                  "Country:",
                  countryData.find((c) => c.countryId === historicData.countryId)?.countryCode || historicData.countryId
                )}
                {renderField(
                  "Claim State:",
                  claimStateData.find((s) => s.claimStateId === historicData.claimStateId)?.description || historicData.claimStateId
                )}
                {renderField("Claim Date:", moment(historicData.claimDate).format("YYYY-MM-DD"))}
                {renderField("Notification Date:", moment(historicData.notificationDate).format("YYYY-MM-DD"))}
                {renderField("Reimbursement Date:", moment(historicData.reimbursementDate).format("YYYY-MM-DD"))}
                {renderField("Pay Date:", moment(historicData.payDate).format("YYYY-MM-DD"))}
                {renderField(
                  "Payable Bank Account:",
                  historicData.claimCode.endsWith("1")
                    ? "Payment To Delta"
                    : bankAccounts.find((b) => b.bankAccountId === historicData.payableBankAccountId)?.iban ||
                        historicData.payableBankAccountId
                )}
              </Box>
            </Paper>
            <Box mb={2}>
              <Typography variant="h6" gutterBottom>
                Invoices
              </Typography>
              <DataGrid
                getRowId={(row) => row.historicClaimInvoiceId}
                rows={invoiceGridData}
                columns={columns}
                disableSelectionOnClick
                components={{ Toolbar: GridToolbar, Footer: GridFooter }}
                density="compact"
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[15, 50, 100]}
                sx={{ height: 500, width: "100%" }}
              />
            </Box>
            <Box display="flex" justifyContent="flex-end">
              <Button variant="contained" onClick={handleClose} color="primary">
                Close
              </Button>
            </Box>
          </>
        ) : (
          <Typography variant="body1">No historic data available.</Typography>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ViewHistoricClaimDialog;
