import React, { useState, useEffect, useCallback } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  Grid,
  Link,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Typography,
  Divider,
  IconButton,
} from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddSupplierPortalInfo from "../../../components/AddSupplierPortalInfo";
import ViewSupplierPortalInfoDialog from "../../../components/ViewSupplierPortalInfoDialog";
import Notification from "../../../components/Notification";
import ConfirmDialog from "../../../components/ConfirmDialog";
import axios from "axios";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
// import { hi } from "date-fns/locale";

const Breadcrumbs = styled(MuiBreadcrumbs)();
const Card = styled(MuiCard)();
const CardContent = styled(MuiCardContent)();

const SupplierPortalInfoDataGrid = () => {
  const columns = [
    {
      field: "supplierPortalId",
      headerName: "ID",
      width: 50,
      hide: true,
    },
    { field: "name", headerName: "Name", flex: 1 },
    { field: "url", headerName: "URL", flex: 3 },
    // {
    //   field: "creationDate",
    //   headerName: "Created",
    //   flex: 0.5,
    //   valueGetter: (params) => new Date(params.row.creationDate).toLocaleDateString(),
    // },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.7,
      renderCell: (params) => {
        return (
          <Box>
            <IconButton
              onClick={(e) => {
                setConfirmDialog({
                  isOpen: true,
                  title: "Delete Supplier Portal Info",
                  subTitle: "Are you sure you want to delete " + params.row.name + "?",
                  onConfirm: () => onDeleteConfirmed(e, params.row),
                });
              }}
            >
              <DeleteIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                setViewDialog({
                  isOpen: true,
                  title: "View Supplier Portal Info",
                  params: params.row,
                });
              }}
            >
              <VisibilityIcon />
            </IconButton>
            <IconButton onClick={() => window.open(params.row.url, "_blank")}>
              <OpenInNewIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const [tableData, setTableData] = useState([]);
  const [notify, setNotify] = useState({ isOpen: false, message: "", type: "" });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [viewDialog, setViewDialog] = useState({
    isOpen: false,
    title: "",
    params: {},
  });

  const fetchSupplierPortalInfos = useCallback(async () => {
    try {
      const response = await axios.get("/api/v1/supplierPortalInfo");
      setTableData(response.data);
    } catch (error) {
      console.error("Error fetching supplier portal info:", error);
    }
  }, []);

  useEffect(() => {
    let isCancelled = false;
    const fetchAsync = async () => {
      try {
        const response = await axios.get("/api/v1/supplierPortalInfo");
        if (!isCancelled) {
          setTableData(response.data);
        }
      } catch (error) {
        console.error("Error fetching supplier portal info:", error);
      }
    };
    fetchAsync();
    return () => {
      isCancelled = true;
    };
  }, [fetchSupplierPortalInfos]);

  const onDeleteConfirmed = (e, row) => {
    axios
      .delete("/api/v1/supplierPortalInfo/" + row.supplierPortalId)
      .then((response) => {
        setTableData((prev) => prev.filter((item) => item.supplierPortalId !== row.supplierPortalId));
        setNotify({
          isOpen: true,
          message: row.name + " successfully deleted",
          type: "success",
        });
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          message: "Error deleting " + row.name,
          type: "error",
        });
      });
    setConfirmDialog({ ...confirmDialog, isOpen: false });
  };

  return (
    <React.Fragment>
      <Helmet title="Supplier Portal Info" />
      <Grid container justifyContent="space-between" spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom>
            Supplier Portal Info
          </Typography>
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Typography>Supplier Portal Info</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
      <Divider my={6} />
      <Card mb={0}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Supplier Portal Info Table
          </Typography>
          <Typography variant="body2" gutterBottom>
            In this table you can search and view Supplier Portal Info.
          </Typography>
        </CardContent>
        <AddSupplierPortalInfo refreshGrid={fetchSupplierPortalInfos} />
        <Notification notify={notify} setNotify={setNotify} />
        <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
        <ViewSupplierPortalInfoDialog viewDialog={viewDialog} setViewDialog={setViewDialog} refreshGrid={fetchSupplierPortalInfos} />
        <Box sx={{ display: "flex", justifyContent: "flex-end", p: 1 }}>{/* Optionally add an export button or similar */}</Box>
        <div style={{ height: 667, width: "100%" }}>
          <DataGrid
            getRowId={(row) => row.supplierPortalId}
            disableSelectionOnClick
            components={{ Toolbar: GridToolbar }}
            density="compact"
            rows={tableData}
            columns={columns}
            pageSize={15}
          />
        </div>
      </Card>
    </React.Fragment>
  );
};

export default SupplierPortalInfoDataGrid;
