import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Grid,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Button as MuiButton,
  Divider,
  TextField as MuiTextField,
  Alert as MuiAlert,
  Typography,
} from "@mui/material";
import axios from "axios";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import apiUtils from "../utils/apiUtils";
import Select from "./SelectBox";

const Card = styled(MuiCard)(spacing);
const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Button = styled(MuiButton)(spacing);

const validationSchema = Yup.object().shape({
  username: Yup.string().required("Required"),
  password: Yup.string().required("Required"),
  supplierPortalId: Yup.string().required("Required"),
});

export default function ViewSupplierPortalLoginDialog(props) {
  const { viewSupplierPortalLoginDialog, setViewSupplierPortalLoginDialog, onSupplierPortalLoginUpdate } = props;
  const supplierPortalData = apiUtils.useApiData("/api/v1/supplierPortalInfo");
  const [alertMessage, setAlertMessage] = React.useState("");
  const [alertSeverity, setAlertSeverity] = React.useState("");

  console.log(viewSupplierPortalLoginDialog);

  const initialValues = {
    username: viewSupplierPortalLoginDialog?.params?.username,
    password: viewSupplierPortalLoginDialog?.params?.password,
    supplierPortalId: viewSupplierPortalLoginDialog?.params?.supplierPortalId,
    email: viewSupplierPortalLoginDialog?.params?.email,
  };

  const handleSubmit = async (values, { resetForm, setErrors, setSubmitting }) => {
    try {
      const updatedPortalLogin = {
        username: values.username,
        password: values.password,
        supplierPortalId: values.supplierPortalId,
        email: values.email,
        entityId: viewSupplierPortalLoginDialog?.params?.entityId,
        customerLoginTypeId: 1, // Possible Types: Unknown = 0, SupplierPortal = 1, EUVATPortal = 2, ExcisePortal = 3, OtherLogins = 4
      };

      const updatePortalLoginResult = await axios.put(
        `/api/v1/customerLogins/${viewSupplierPortalLoginDialog?.params?.customerLoginId}`,
        updatedPortalLogin
      );

      // On success, show success alert for 3 seconds then close the dialog
      // setAlertMessage("Your data has been submitted successfully!");
      // setAlertSeverity("success");
      setSubmitting(false);
      onSupplierPortalLoginUpdate();
      setAlertMessage("");
      setAlertSeverity("");
      setViewSupplierPortalLoginDialog({
        ...viewSupplierPortalLoginDialog,
        isOpen: false,
      });
    } catch (error) {
      // On error, show error alert for 3 seconds
      setAlertMessage(error.message);
      setAlertSeverity("error");
      setSubmitting(false);
      setTimeout(() => {
        setAlertMessage("");
        setAlertSeverity("");
      }, 3000);
    }
  };

  return (
    <Dialog
      open={viewSupplierPortalLoginDialog.isOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="alert-dialog-title">{viewSupplierPortalLoginDialog.title}</DialogTitle>
      <Divider />
      {alertMessage && (
        <Alert severity={alertSeverity} my={3}>
          {alertMessage}
        </Alert>
      )}
      <DialogContent>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, status }) => (
            <Card mb={6}>
              <CardContent>
                {status && status.sent && (
                  <Alert severity="success" my={3}>
                    Your data has been submitted successfully!
                  </Alert>
                )}

                {status && status.submit && (
                  <Alert severity="error" my={3}>
                    {status.submit}
                  </Alert>
                )}

                {isSubmitting ? (
                  <Box display="flex" justifyContent="center" alignItems="center" my={20} mx={40} height={375}>
                    <Typography variant="h6" mr={2}>
                      Updating Portal Login
                    </Typography>
                    <CircularProgress />
                  </Box>
                ) : (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={6}>
                      <Grid item md={12} xs={12}>
                        <Select
                          name="supplierPortalId"
                          label="Supplier Portal"
                          options={JSON.parse(
                            JSON.stringify(JSON.parse(JSON.stringify(supplierPortalData).split('"supplierPortalId":').join('"key":')))
                              .split('"name":')
                              .join('"value":')
                          )}
                          onChange={handleChange}
                          formControlCustomWidth="100%"
                          my={0}
                        />
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <TextField
                          name="username"
                          label="Username"
                          value={values.username}
                          error={Boolean(touched.username && errors.username)}
                          fullWidth
                          helperText={touched.username && errors.username}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <TextField
                          name="password"
                          label="Password"
                          value={values.password}
                          error={Boolean(touched.password && errors.password)}
                          fullWidth
                          helperText={touched.password && errors.password}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <TextField
                          name="email"
                          label="Emailaddress"
                          value={values.email}
                          error={Boolean(touched.email && errors.email)}
                          fullWidth
                          helperText={touched.email && errors.email}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="text"
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      mt={3}
                      // onClick={() => setOpen(false)}
                      my={6}
                      mr={4}
                      onClick={handleSubmit}
                    >
                      Update Portal Login
                    </Button>
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      mt={3}
                      //   onClick={() => window.location.reload(false)}
                      my={2}
                      // marginLeft={2}
                      onClick={() => {
                        setViewSupplierPortalLoginDialog({
                          ...viewSupplierPortalLoginDialog,
                          isOpen: false,
                        });
                      }}
                    >
                      Close
                    </Button>
                  </form>
                )}
              </CardContent>
            </Card>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
